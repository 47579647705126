:root, :host {
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
  }
  
  svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
  }
  
  .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em;
  }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em;
  }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.0714285705em;
  }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em;
  }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em;
  }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em;
  }
  .svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto;
  }
  .svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto;
  }
  .svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em;
  }
  .svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em);
  }
  
  .fa-layers svg.svg-inline--fa {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .fa-layers-counter, .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center;
  }
  
  .fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -0.125em;
    width: 1em;
  }
  .fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  
  .fa-layers-text {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  
  .fa-layers-counter {
    background-color: var(--fa-counter-background-color, #ff253a);
    border-radius: var(--fa-counter-border-radius, 1em);
    box-sizing: border-box;
    color: var(--fa-inverse, #fff);
    line-height: var(--fa-counter-line-height, 1);
    max-width: var(--fa-counter-max-width, 5em);
    min-width: var(--fa-counter-min-width, 1.5em);
    overflow: hidden;
    padding: var(--fa-counter-padding, 0.25em 0.5em);
    right: var(--fa-right, 0);
    text-overflow: ellipsis;
    top: var(--fa-top, 0);
    -webkit-transform: scale(var(--fa-counter-scale, 0.25));
            transform: scale(var(--fa-counter-scale, 0.25));
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  
  .fa-layers-bottom-right {
    bottom: var(--fa-bottom, 0);
    right: var(--fa-right, 0);
    top: auto;
    -webkit-transform: scale(var(--fa-layers-scale, 0.25));
            transform: scale(var(--fa-layers-scale, 0.25));
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
  
  .fa-layers-bottom-left {
    bottom: var(--fa-bottom, 0);
    left: var(--fa-left, 0);
    right: auto;
    top: auto;
    -webkit-transform: scale(var(--fa-layers-scale, 0.25));
            transform: scale(var(--fa-layers-scale, 0.25));
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
  
  .fa-layers-top-right {
    top: var(--fa-top, 0);
    right: var(--fa-right, 0);
    -webkit-transform: scale(var(--fa-layers-scale, 0.25));
            transform: scale(var(--fa-layers-scale, 0.25));
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  
  .fa-layers-top-left {
    left: var(--fa-left, 0);
    right: auto;
    top: var(--fa-top, 0);
    -webkit-transform: scale(var(--fa-layers-scale, 0.25));
            transform: scale(var(--fa-layers-scale, 0.25));
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
  
  .fa-1x {
    font-size: 1em;
  }
  
  .fa-2x {
    font-size: 2em;
  }
  
  .fa-3x {
    font-size: 3em;
  }
  
  .fa-4x {
    font-size: 4em;
  }
  
  .fa-5x {
    font-size: 5em;
  }
  
  .fa-6x {
    font-size: 6em;
  }
  
  .fa-7x {
    font-size: 7em;
  }
  
  .fa-8x {
    font-size: 8em;
  }
  
  .fa-9x {
    font-size: 9em;
  }
  
  .fa-10x {
    font-size: 10em;
  }
  
  .fa-2xs {
    font-size: 0.625em;
    line-height: 0.1em;
    vertical-align: 0.225em;
  }
  
  .fa-xs {
    font-size: 0.75em;
    line-height: 0.0833333337em;
    vertical-align: 0.125em;
  }
  
  .fa-sm {
    font-size: 0.875em;
    line-height: 0.0714285718em;
    vertical-align: 0.0535714295em;
  }
  
  .fa-lg {
    font-size: 1.25em;
    line-height: 0.05em;
    vertical-align: -0.075em;
  }
  
  .fa-xl {
    font-size: 1.5em;
    line-height: 0.0416666682em;
    vertical-align: -0.125em;
  }
  
  .fa-2xl {
    font-size: 2em;
    line-height: 0.03125em;
    vertical-align: -0.1875em;
  }
  
  .fa-fw {
    text-align: center;
    width: 1.25em;
  }
  
  .fa-ul {
    list-style-type: none;
    margin-left: var(--fa-li-margin, 2.5em);
    padding-left: 0;
  }
  .fa-ul > li {
    position: relative;
  }
  
  .fa-li {
    left: calc(var(--fa-li-width, 2em) * -1);
    position: absolute;
    text-align: center;
    width: var(--fa-li-width, 2em);
    line-height: inherit;
  }
  
  .fa-border {
    border-color: var(--fa-border-color, #eee);
    border-radius: var(--fa-border-radius, 0.1em);
    border-style: var(--fa-border-style, solid);
    border-width: var(--fa-border-width, 0.08em);
    padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
  }
  
  .fa-pull-left {
    float: left;
    margin-right: var(--fa-pull-margin, 0.3em);
  }
  
  .fa-pull-right {
    float: right;
    margin-left: var(--fa-pull-margin, 0.3em);
  }
  
  .fa-beat {
    -webkit-animation-name: fa-beat;
            animation-name: fa-beat;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
            animation-timing-function: var(--fa-animation-timing, ease-in-out);
  }
  
  .fa-bounce {
    -webkit-animation-name: fa-bounce;
            animation-name: fa-bounce;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
            animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
  }
  
  .fa-fade {
    -webkit-animation-name: fa-fade;
            animation-name: fa-fade;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
            animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  }
  
  .fa-beat-fade {
    -webkit-animation-name: fa-beat-fade;
            animation-name: fa-beat-fade;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
            animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  }
  
  .fa-flip {
    -webkit-animation-name: fa-flip;
            animation-name: fa-flip;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
            animation-timing-function: var(--fa-animation-timing, ease-in-out);
  }
  
  .fa-shake {
    -webkit-animation-name: fa-shake;
            animation-name: fa-shake;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
            animation-timing-function: var(--fa-animation-timing, linear);
  }
  
  .fa-spin {
    -webkit-animation-name: fa-spin;
            animation-name: fa-spin;
    -webkit-animation-delay: var(--fa-animation-delay, 0s);
            animation-delay: var(--fa-animation-delay, 0s);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 2s);
            animation-duration: var(--fa-animation-duration, 2s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
            animation-timing-function: var(--fa-animation-timing, linear);
  }
  
  .fa-spin-reverse {
    --fa-animation-direction: reverse;
  }
  
  .fa-pulse,
  .fa-spin-pulse {
    -webkit-animation-name: fa-spin;
            animation-name: fa-spin;
    -webkit-animation-direction: var(--fa-animation-direction, normal);
            animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
            animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
            animation-timing-function: var(--fa-animation-timing, steps(8));
  }
  
  @media (prefers-reduced-motion: reduce) {
    .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
      -webkit-animation-delay: -1ms;
              animation-delay: -1ms;
      -webkit-animation-duration: 1ms;
              animation-duration: 1ms;
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
      -webkit-transition-duration: 0s;
              transition-duration: 0s;
    }
  }
  @-webkit-keyframes fa-beat {
    0%, 90% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    45% {
      -webkit-transform: scale(var(--fa-beat-scale, 1.25));
              transform: scale(var(--fa-beat-scale, 1.25));
    }
  }
  @keyframes fa-beat {
    0%, 90% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    45% {
      -webkit-transform: scale(var(--fa-beat-scale, 1.25));
              transform: scale(var(--fa-beat-scale, 1.25));
    }
  }
  @-webkit-keyframes fa-bounce {
    0% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
    10% {
      -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
              transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    }
    30% {
      -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
              transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    }
    50% {
      -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
              transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    }
    57% {
      -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
              transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    }
    64% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
    100% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
  }
  @keyframes fa-bounce {
    0% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
    10% {
      -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
              transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    }
    30% {
      -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
              transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    }
    50% {
      -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
              transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    }
    57% {
      -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
              transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    }
    64% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
    100% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0);
    }
  }
  @-webkit-keyframes fa-fade {
    50% {
      opacity: var(--fa-fade-opacity, 0.4);
    }
  }
  @keyframes fa-fade {
    50% {
      opacity: var(--fa-fade-opacity, 0.4);
    }
  }
  @-webkit-keyframes fa-beat-fade {
    0%, 100% {
      opacity: var(--fa-beat-fade-opacity, 0.4);
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 1;
      -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
              transform: scale(var(--fa-beat-fade-scale, 1.125));
    }
  }
  @keyframes fa-beat-fade {
    0%, 100% {
      opacity: var(--fa-beat-fade-opacity, 0.4);
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 1;
      -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
              transform: scale(var(--fa-beat-fade-scale, 1.125));
    }
  }
  @-webkit-keyframes fa-flip {
    50% {
      -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
              transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    }
  }
  @keyframes fa-flip {
    50% {
      -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
              transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    }
  }
  @-webkit-keyframes fa-shake {
    0% {
      -webkit-transform: rotate(-15deg);
              transform: rotate(-15deg);
    }
    4% {
      -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
    }
    8%, 24% {
      -webkit-transform: rotate(-18deg);
              transform: rotate(-18deg);
    }
    12%, 28% {
      -webkit-transform: rotate(18deg);
              transform: rotate(18deg);
    }
    16% {
      -webkit-transform: rotate(-22deg);
              transform: rotate(-22deg);
    }
    20% {
      -webkit-transform: rotate(22deg);
              transform: rotate(22deg);
    }
    32% {
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
    }
    36% {
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
    }
    40%, 100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  @keyframes fa-shake {
    0% {
      -webkit-transform: rotate(-15deg);
              transform: rotate(-15deg);
    }
    4% {
      -webkit-transform: rotate(15deg);
              transform: rotate(15deg);
    }
    8%, 24% {
      -webkit-transform: rotate(-18deg);
              transform: rotate(-18deg);
    }
    12%, 28% {
      -webkit-transform: rotate(18deg);
              transform: rotate(18deg);
    }
    16% {
      -webkit-transform: rotate(-22deg);
              transform: rotate(-22deg);
    }
    20% {
      -webkit-transform: rotate(22deg);
              transform: rotate(22deg);
    }
    32% {
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
    }
    36% {
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
    }
    40%, 100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .fa-rotate-90 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .fa-rotate-180 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  
  .fa-rotate-270 {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  
  .fa-flip-horizontal {
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
  }
  
  .fa-flip-vertical {
    -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
  }
  
  .fa-flip-both,
  .fa-flip-horizontal.fa-flip-vertical {
    -webkit-transform: scale(-1, -1);
            transform: scale(-1, -1);
  }
  
  .fa-rotate-by {
    -webkit-transform: rotate(var(--fa-rotate-angle, none));
            transform: rotate(var(--fa-rotate-angle, none));
  }
  
  .fa-stack {
    display: inline-block;
    vertical-align: middle;
    height: 2em;
    position: relative;
    width: 2.5em;
  }
  
  .fa-stack-1x,
  .fa-stack-2x {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: var(--fa-stack-z-index, auto);
  }
  
  .svg-inline--fa.fa-stack-1x {
    height: 1em;
    width: 1.25em;
  }
  .svg-inline--fa.fa-stack-2x {
    height: 2em;
    width: 2.5em;
  }
  
  .fa-inverse {
    color: var(--fa-inverse, #fff);
  }
  
  .sr-only,
  .fa-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .sr-only-focusable:not(:focus),
  .fa-sr-only-focusable:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .svg-inline--fa .fa-primary {
    fill: var(--fa-primary-color, currentColor);
    opacity: var(--fa-primary-opacity, 1);
  }
  
  .svg-inline--fa .fa-secondary {
    fill: var(--fa-secondary-color, currentColor);
    opacity: var(--fa-secondary-opacity, 0.4);
  }
  
  .svg-inline--fa.fa-swap-opacity .fa-primary {
    opacity: var(--fa-secondary-opacity, 0.4);
  }
  
  .svg-inline--fa.fa-swap-opacity .fa-secondary {
    opacity: var(--fa-primary-opacity, 1);
  }
  
  .svg-inline--fa mask .fa-primary,
  .svg-inline--fa mask .fa-secondary {
    fill: black;
  }
  
  .fad.fa-inverse,
  .fa-duotone.fa-inverse {
    color: var(--fa-inverse, #fff);
  }

  @media only screen and (min-width: 768px) {
    bsk-header:is([is=centre-with-burger]) div[class*=container]>* {
        width: 33% !important;
        display: flex !important;
    }
}




body.compensate-for-scrollbar{
    overflow:hidden
}
.fancybox-active{
    height:auto
}
.fancybox-is-hidden{
    left:-9999px;
    margin:0;
    position:absolute!important;
    top:-9999px;
    visibility:hidden
}
.fancybox-container{
    -webkit-tap-highlight-color:transparent;
    -webkit-backface-visibility:hidden;
    height:100%;
    left:0;
    outline:none;
    position:fixed;
    top:0;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    transform:translateZ(0);
    width:100%;
    z-index:99992
}
.fancybox-container *{
    box-sizing:border-box
}
.fancybox-bg,.fancybox-inner,.fancybox-outer,.fancybox-stage{
    bottom:0;
    left:0;
    position:absolute;
    right:0;
    top:0
}
.fancybox-outer{
    -webkit-overflow-scrolling:touch;
    overflow-y:auto
}
.fancybox-bg{
    background:#1e1e1e;
    opacity:0;
    transition-duration:inherit;
    transition-property:opacity;
    transition-timing-function:cubic-bezier(.47,0,.74,.71)
}
.fancybox-is-open .fancybox-bg{
    opacity:.9;
    transition-timing-function:cubic-bezier(.22,.61,.36,1)
}
.fancybox-caption,.fancybox-infobar,.fancybox-navigation .fancybox-button,.fancybox-toolbar{
    direction:ltr;
    opacity:0;
    position:absolute;
    transition:opacity .25s ease,visibility 0s ease .25s;
    visibility:hidden;
    z-index:99997
}
.fancybox-show-caption .fancybox-caption,.fancybox-show-infobar .fancybox-infobar,.fancybox-show-nav .fancybox-navigation .fancybox-button,.fancybox-show-toolbar .fancybox-toolbar{
    opacity:1;
    transition:opacity .25s ease 0s,visibility 0s ease 0s;
    visibility:visible
}
.fancybox-infobar{
    -webkit-font-smoothing:subpixel-antialiased;
    -webkit-touch-callout:none;
    color:#ccc;
    font-size:13px;
    height:44px;
    left:0;
    line-height:44px;
    min-width:44px;
    mix-blend-mode:difference;
    padding:0 10px;
    pointer-events:none;
    top:0;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.fancybox-toolbar{
    right:0;
    top:0
}
.fancybox-stage{
    direction:ltr;
    overflow:visible;
    transform:translateZ(0);
    z-index:99994
}
.fancybox-is-open .fancybox-stage{
    overflow:hidden
}
.fancybox-slide{
    -webkit-overflow-scrolling:touch;
    -webkit-backface-visibility:hidden;
    display:none;
    height:100%;
    left:0;
    outline:none;
    overflow:auto;
    padding:44px;
    position:absolute;
    text-align:center;
    top:0;
    transition-property:transform,opacity;
    white-space:normal;
    width:100%;
    z-index:99994
}
.fancybox-slide:before{
    content:"";
    display:inline-block;
    font-size:0;
    height:100%;
    vertical-align:middle;
    width:0
}
.fancybox-is-sliding .fancybox-slide,.fancybox-slide--current,.fancybox-slide--next,.fancybox-slide--previous{
    display:block
}
.fancybox-slide--image{
    overflow:hidden;
    padding:44px 0
}
.fancybox-slide--image:before{
    display:none
}
.fancybox-slide--html{
    padding:6px
}
.fancybox-content{
    -webkit-overflow-scrolling:touch;
    background:#fff;
    display:inline-block;
    margin:0;
    max-width:100%;
    overflow:auto;
    padding:44px;
    position:relative;
    text-align:left;
    vertical-align:middle
}
.fancybox-slide--image .fancybox-content{
    animation-timing-function:cubic-bezier(.5,0,.14,1);
    -webkit-backface-visibility:hidden;
    background:transparent;
    background-repeat:no-repeat;
    background-size:100% 100%;
    left:0;
    max-width:none;
    overflow:visible;
    padding:0;
    position:absolute;
    top:0;
    -ms-transform-origin:top left;
    transform-origin:top left;
    transition-property:transform,opacity;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    z-index:99995
}
.fancybox-can-zoomOut .fancybox-content{
    cursor:zoom-out
}
.fancybox-can-zoomIn .fancybox-content{
    cursor:zoom-in
}
.fancybox-can-pan .fancybox-content,.fancybox-can-swipe .fancybox-content{
    cursor:-webkit-grab;
    cursor:grab
}
.fancybox-is-grabbing .fancybox-content{
    cursor:-webkit-grabbing;
    cursor:grabbing
}
.fancybox-container [data-selectable=true]{
    cursor:text
}
.fancybox-image,.fancybox-spaceball{
    background:transparent;
    border:0;
    height:100%;
    left:0;
    margin:0;
    max-height:none;
    max-width:none;
    padding:0;
    position:absolute;
    top:0;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    width:100%
}
.fancybox-spaceball{
    z-index:1
}
.fancybox-slide--iframe .fancybox-content,.fancybox-slide--map .fancybox-content,.fancybox-slide--pdf .fancybox-content,.fancybox-slide--video .fancybox-content{
    height:100%;
    overflow:visible;
    padding:0;
    width:100%
}
.fancybox-slide--video .fancybox-content{
    background:#000
}
.fancybox-slide--map .fancybox-content{
    background:#e5e3df
}
.fancybox-slide--iframe .fancybox-content{
    background:#fff
}
.fancybox-iframe,.fancybox-video{
    background:transparent;
    border:0;
    display:block;
    height:100%;
    margin:0;
    overflow:hidden;
    padding:0;
    width:100%
}
.fancybox-iframe{
    left:0;
    position:absolute;
    top:0
}
.fancybox-error{
    background:#fff;
    cursor:default;
    max-width:400px;
    padding:40px;
    width:100%
}
.fancybox-error p{
    color:#444;
    font-size:16px;
    line-height:20px;
    margin:0;
    padding:0
}
.fancybox-button{
    background:rgba(30,30,30,.6);
    border:0;
    border-radius:0;
    box-shadow:none;
    cursor:pointer;
    display:inline-block;
    height:44px;
    margin:0;
    padding:10px;
    position:relative;
    transition:color .2s;
    vertical-align:top;
    visibility:inherit;
    width:44px
}
.fancybox-button,.fancybox-button:link,.fancybox-button:visited{
    color:#ccc
}
.fancybox-button:hover{
    color:#fff
}
.fancybox-button:focus{
    outline:none
}
.fancybox-button.fancybox-focus{
    outline:1px dotted
}
.fancybox-button[disabled],.fancybox-button[disabled]:hover{
    color:#888;
    cursor:default;
    outline:none
}
.fancybox-button div{
    height:100%
}
.fancybox-button svg{
    display:block;
    height:100%;
    overflow:visible;
    position:relative;
    width:100%
}
.fancybox-button svg path{
    fill:currentColor;
    stroke-width:0
}
.fancybox-button--fsenter svg:nth-child(2),.fancybox-button--fsexit svg:first-child,.fancybox-button--pause svg:first-child,.fancybox-button--play svg:nth-child(2){
    display:none
}
.fancybox-progress{
    background:#ff5268;
    height:2px;
    left:0;
    position:absolute;
    right:0;
    top:0;
    -ms-transform:scaleX(0);
    transform:scaleX(0);
    -ms-transform-origin:0;
    transform-origin:0;
    transition-property:transform;
    transition-timing-function:linear;
    z-index:99998
}
.fancybox-close-small{
    background:transparent;
    border:0;
    border-radius:0;
    color:#ccc;
    cursor:pointer;
    opacity:.8;
    padding:8px;
    position:absolute;
    right:-12px;
    top:-44px;
    z-index:401
}
.fancybox-close-small:hover{
    color:#fff;
    opacity:1
}
.fancybox-slide--html .fancybox-close-small{
    color:currentColor;
    padding:10px;
    right:0;
    top:0
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content{
    overflow:hidden
}
.fancybox-is-scaling .fancybox-close-small,.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small{
    display:none
}
.fancybox-navigation .fancybox-button{
    background-clip:content-box;
    height:100px;
    opacity:0;
    position:absolute;
    top:calc(50% - 50px);
    width:70px
}
.fancybox-navigation .fancybox-button div{
    padding:7px
}
.fancybox-navigation .fancybox-button--arrow_left{
    left:0;
    left:env(safe-area-inset-left);
    padding:31px 26px 31px 6px
}
.fancybox-navigation .fancybox-button--arrow_right{
    padding:31px 6px 31px 26px;
    right:0;
    right:env(safe-area-inset-right)
}
.fancybox-caption{
    background:linear-gradient(0deg,rgba(0,0,0,.85) 0,rgba(0,0,0,.3) 50%,rgba(0,0,0,.15) 65%,rgba(0,0,0,.075) 75.5%,rgba(0,0,0,.037) 82.85%,rgba(0,0,0,.019) 88%,transparent);
    bottom:0;
    color:#eee;
    font-size:14px;
    font-weight:400;
    left:0;
    line-height:1.5;
    padding:75px 44px 25px;
    pointer-events:none;
    right:0;
    text-align:center;
    z-index:99996
}
@supports (padding:max(0px)){
    .fancybox-caption{
        padding:75px max(44px,env(safe-area-inset-right)) max(25px,env(safe-area-inset-bottom)) max(44px,env(safe-area-inset-left))
    }
}
.fancybox-caption--separate{
    margin-top:-50px
}
.fancybox-caption__body{
    max-height:50vh;
    overflow:auto;
    pointer-events:all
}
.fancybox-caption a,.fancybox-caption a:link,.fancybox-caption a:visited{
    color:#ccc;
    text-decoration:none
}
.fancybox-caption a:hover{
    color:#fff;
    text-decoration:underline
}
.fancybox-loading{
    animation:fancybox-rotate 1s linear infinite;
    background:transparent;
    border:4px solid;
    border-color:#888 #888 #fff;
    border-radius:50%;
    height:50px;
    left:50%;
    margin:-25px 0 0 -25px;
    opacity:.7;
    padding:0;
    position:absolute;
    top:50%;
    width:50px;
    z-index:99999
}
@keyframes fancybox-rotate{
    to{
        transform:rotate(1turn)
    }
}
.fancybox-animated{
    transition-timing-function:cubic-bezier(0,0,.25,1)
}
.fancybox-fx-slide.fancybox-slide--previous{
    opacity:0;
    transform:translate3d(-100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--next{
    opacity:0;
    transform:translate3d(100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--current{
    opacity:1;
    transform:translateZ(0)
}
.fancybox-fx-fade.fancybox-slide--next,.fancybox-fx-fade.fancybox-slide--previous{
    opacity:0;
    transition-timing-function:cubic-bezier(.19,1,.22,1)
}
.fancybox-fx-fade.fancybox-slide--current{
    opacity:1
}
.fancybox-fx-zoom-in-out.fancybox-slide--previous{
    opacity:0;
    transform:scale3d(1.5,1.5,1.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--next{
    opacity:0;
    transform:scale3d(.5,.5,.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--current{
    opacity:1;
    transform:scaleX(1)
}
.fancybox-fx-rotate.fancybox-slide--previous{
    opacity:0;
    -ms-transform:rotate(-1turn);
    transform:rotate(-1turn)
}
.fancybox-fx-rotate.fancybox-slide--next{
    opacity:0;
    -ms-transform:rotate(1turn);
    transform:rotate(1turn)
}
.fancybox-fx-rotate.fancybox-slide--current{
    opacity:1;
    -ms-transform:rotate(0deg);
    transform:rotate(0deg)
}
.fancybox-fx-circular.fancybox-slide--previous{
    opacity:0;
    transform:scale3d(0,0,0) translate3d(-100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--next{
    opacity:0;
    transform:scale3d(0,0,0) translate3d(100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--current{
    opacity:1;
    transform:scaleX(1) translateZ(0)
}
.fancybox-fx-tube.fancybox-slide--previous{
    transform:translate3d(-100%,0,0) scale(.1) skew(-10deg)
}
.fancybox-fx-tube.fancybox-slide--next{
    transform:translate3d(100%,0,0) scale(.1) skew(10deg)
}
.fancybox-fx-tube.fancybox-slide--current{
    transform:translateZ(0) scale(1)
}
@media (max-height:576px){
    .fancybox-slide{
        padding-left:6px;
        padding-right:6px
    }
    .fancybox-slide--image{
        padding:6px 0
    }
    .fancybox-close-small{
        right:-6px
    }
    .fancybox-slide--image .fancybox-close-small{
        background:#4e4e4e;
        color:#f2f4f6;
        height:36px;
        opacity:1;
        padding:6px;
        right:0;
        top:0;
        width:36px
    }
    .fancybox-caption{
        padding-left:12px;
        padding-right:12px
    }
    @supports (padding:max(0px)){
        .fancybox-caption{
            padding-left:max(12px,env(safe-area-inset-left));
            padding-right:max(12px,env(safe-area-inset-right))
        }
    }
}
.fancybox-share{
    background:#f4f4f4;
    border-radius:3px;
    max-width:90%;
    padding:30px;
    text-align:center
}
.fancybox-share h1{
    color:#222;
    font-size:35px;
    font-weight:700;
    margin:0 0 20px
}
.fancybox-share p{
    margin:0;
    padding:0
}
.fancybox-share__button{
    border:0;
    border-radius:3px;
    display:inline-block;
    font-size:14px;
    font-weight:700;
    line-height:40px;
    margin:0 5px 10px;
    min-width:130px;
    padding:0 15px;
    text-decoration:none;
    transition:all .2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    white-space:nowrap
}
.fancybox-share__button:link,.fancybox-share__button:visited{
    color:#fff
}
.fancybox-share__button:hover{
    text-decoration:none
}
.fancybox-share__button--fb{
    background:#3b5998
}
.fancybox-share__button--fb:hover{
    background:#344e86
}
.fancybox-share__button--pt{
    background:#bd081d
}
.fancybox-share__button--pt:hover{
    background:#aa0719
}
.fancybox-share__button--tw{
    background:#1da1f2
}
.fancybox-share__button--tw:hover{
    background:#0d95e8
}
.fancybox-share__button svg{
    height:25px;
    margin-right:7px;
    position:relative;
    top:-1px;
    vertical-align:middle;
    width:25px
}
.fancybox-share__button svg path{
    fill:#fff
}
.fancybox-share__input{
    background:transparent;
    border:0;
    border-bottom:1px solid #d7d7d7;
    border-radius:0;
    color:#5d5b5b;
    font-size:14px;
    margin:10px 0 0;
    outline:none;
    padding:10px 15px;
    width:100%
}
.fancybox-thumbs{
    -webkit-overflow-scrolling:touch;
    -ms-overflow-style:-ms-autohiding-scrollbar;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    background:#ddd;
    bottom:0;
    display:none;
    margin:0;
    padding:2px 2px 4px;
    position:absolute;
    right:0;
    top:0;
    width:212px;
    z-index:99995
}
.fancybox-thumbs-x{
    overflow-x:auto;
    overflow-y:hidden
}
.fancybox-show-thumbs .fancybox-thumbs{
    display:block
}
.fancybox-show-thumbs .fancybox-inner{
    right:212px
}
.fancybox-thumbs__list{
    font-size:0;
    height:100%;
    list-style:none;
    margin:0;
    overflow-x:hidden;
    overflow-y:auto;
    padding:0;
    position:absolute;
    position:relative;
    white-space:nowrap;
    width:100%
}
.fancybox-thumbs-x .fancybox-thumbs__list{
    overflow:hidden
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar{
    width:7px
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track{
    background:#fff;
    border-radius:10px;
    box-shadow:inset 0 0 6px rgba(0,0,0,.3)
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb{
    background:#2a2a2a;
    border-radius:10px
}
.fancybox-thumbs__list a{
    -webkit-tap-highlight-color:transparent;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    background-color:rgba(0,0,0,.1);
    background-position:50%;
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
    float:left;
    height:75px;
    margin:2px;
    max-height:calc(100% - 8px);
    max-width:calc(50% - 4px);
    outline:none;
    overflow:hidden;
    padding:0;
    position:relative;
    width:100px
}
.fancybox-thumbs__list a:before{
    border:6px solid #ff5268;
    bottom:0;
    content:"";
    left:0;
    opacity:0;
    position:absolute;
    right:0;
    top:0;
    transition:all .2s cubic-bezier(.25,.46,.45,.94);
    z-index:99991
}
.fancybox-thumbs__list a:focus:before{
    opacity:.5
}
.fancybox-thumbs__list a.fancybox-thumbs-active:before{
    opacity:1
}
@media (max-width:576px){
    .fancybox-thumbs{
        width:110px
    }
    .fancybox-show-thumbs .fancybox-inner{
        right:110px
    }
    .fancybox-thumbs__list a{
        max-width:calc(100% - 10px)
    }
}
.slick-slider{
    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    box-sizing:border-box;
    touch-action:pan-y;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none
}
.slick-list,.slick-slider{
    display:block;
    position:relative
}
.slick-list{
    margin:0;
    overflow:hidden;
    padding:0
}
.slick-list:focus{
    outline:none
}
.slick-list.dragging{
    cursor:pointer;
    cursor:hand
}
.slick-slider .slick-list,.slick-slider .slick-track{
    transform:translateZ(0)
}
.slick-track{
    display:block;
    left:0;
    margin-left:auto;
    margin-right:auto;
    position:relative;
    top:0
}
.slick-track:after,.slick-track:before{
    content:"";
    display:table
}
.slick-track:after{
    clear:both
}
.slick-loading .slick-track{
    visibility:hidden
}
.slick-slide{
    display:none;
    float:left;
    height:100%;
    min-height:1px
}
[dir=rtl] .slick-slide{
    float:right
}
.slick-slide img{
    display:block
}
.slick-slide.slick-loading img{
    display:none
}
.slick-slide.dragging img{
    pointer-events:none
}
.slick-initialized .slick-slide{
    display:block
}
.slick-loading .slick-slide{
    visibility:hidden
}
.slick-vertical .slick-slide{
    border:1px solid transparent;
    display:block;
    height:auto
}
.slick-arrow.slick-hidden{
    display:none
}
.flatpickr-calendar{
    -webkit-animation:none;
    animation:none;
    background:transparent;
    background:#fff;
    border:0;
    border-radius:5px;
    box-shadow:1px 0 0 #e6e6e6,-1px 0 0 #e6e6e6,0 1px 0 #e6e6e6,0 -1px 0 #e6e6e6,0 3px 13px rgba(0,0,0,.08);
    box-sizing:border-box;
    direction:ltr;
    display:none;
    font-size:14px;
    line-height:24px;
    opacity:0;
    padding:0;
    position:absolute;
    text-align:center;
    touch-action:manipulation;
    visibility:hidden;
    width:307.875px
}
.flatpickr-calendar.inline,.flatpickr-calendar.open{
    max-height:640px;
    opacity:1;
    visibility:visible
}
.flatpickr-calendar.open{
    display:inline-block;
    z-index:99999
}
.flatpickr-calendar.animate.open{
    -webkit-animation:fpFadeInDown .3s cubic-bezier(.23,1,.32,1);
    animation:fpFadeInDown .3s cubic-bezier(.23,1,.32,1)
}
.flatpickr-calendar.inline{
    display:block;
    position:relative;
    top:2px
}
.flatpickr-calendar.static{
    position:absolute;
    top:calc(100% + 2px)
}
.flatpickr-calendar.static.open{
    display:block;
    z-index:999
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7){
    box-shadow:none!important
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1){
    box-shadow:-2px 0 0 #e6e6e6,5px 0 0 #e6e6e6
}
.flatpickr-calendar .hasTime .dayContainer,.flatpickr-calendar .hasWeeks .dayContainer{
    border-bottom:0;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0
}
.flatpickr-calendar .hasWeeks .dayContainer{
    border-left:0
}
.flatpickr-calendar.hasTime .flatpickr-time{
    border-top:1px solid #e6e6e6;
    height:40px
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time{
    height:auto
}
.flatpickr-calendar:after,.flatpickr-calendar:before{
    border:solid transparent;
    content:"";
    display:block;
    height:0;
    left:22px;
    pointer-events:none;
    position:absolute;
    width:0
}
.flatpickr-calendar.arrowRight:after,.flatpickr-calendar.arrowRight:before,.flatpickr-calendar.rightMost:after,.flatpickr-calendar.rightMost:before{
    left:auto;
    right:22px
}
.flatpickr-calendar.arrowCenter:after,.flatpickr-calendar.arrowCenter:before{
    left:50%;
    right:50%
}
.flatpickr-calendar:before{
    border-width:5px;
    margin:0 -5px
}
.flatpickr-calendar:after{
    border-width:4px;
    margin:0 -4px
}
.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before{
    bottom:100%
}
.flatpickr-calendar.arrowTop:before{
    border-bottom-color:#e6e6e6
}
.flatpickr-calendar.arrowTop:after{
    border-bottom-color:#fff
}
.flatpickr-calendar.arrowBottom:after,.flatpickr-calendar.arrowBottom:before{
    top:100%
}
.flatpickr-calendar.arrowBottom:before{
    border-top-color:#e6e6e6
}
.flatpickr-calendar.arrowBottom:after{
    border-top-color:#fff
}
.flatpickr-calendar:focus{
    outline:0
}
.flatpickr-wrapper{
    display:inline-block;
    position:relative
}
.flatpickr-months{
    display:flex
}
.flatpickr-months .flatpickr-month{
    background:transparent;
    flex:1 1;
    line-height:1;
    overflow:hidden;
    position:relative;
    text-align:center
}
.flatpickr-months .flatpickr-month,.flatpickr-months .flatpickr-next-month,.flatpickr-months .flatpickr-prev-month{
    fill:rgba(0,0,0,.9);
    color:rgba(0,0,0,.9);
    height:34px;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none
}
.flatpickr-months .flatpickr-next-month,.flatpickr-months .flatpickr-prev-month{
    cursor:pointer;
    padding:10px;
    position:absolute;
    text-decoration:none;
    top:0;
    z-index:3
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,.flatpickr-months .flatpickr-prev-month.flatpickr-disabled{
    display:none
}
.flatpickr-months .flatpickr-next-month i,.flatpickr-months .flatpickr-prev-month i{
    position:relative
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month{
    left:0
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,.flatpickr-months .flatpickr-prev-month.flatpickr-next-month{
    right:0
}
.flatpickr-months .flatpickr-next-month:hover,.flatpickr-months .flatpickr-prev-month:hover{
    color:#959ea9
}
.flatpickr-months .flatpickr-next-month:hover svg,.flatpickr-months .flatpickr-prev-month:hover svg{
    fill:#f64747
}
.flatpickr-months .flatpickr-next-month svg,.flatpickr-months .flatpickr-prev-month svg{
    height:14px;
    width:14px
}
.flatpickr-months .flatpickr-next-month svg path,.flatpickr-months .flatpickr-prev-month svg path{
    fill:inherit;
    transition:fill .1s
}
.numInputWrapper{
    height:auto;
    position:relative
}
.numInputWrapper input,.numInputWrapper span{
    display:inline-block
}
.numInputWrapper input{
    width:100%
}
.numInputWrapper input::-ms-clear{
    display:none
}
.numInputWrapper input::-webkit-inner-spin-button,.numInputWrapper input::-webkit-outer-spin-button{
    -webkit-appearance:none;
    margin:0
}
.numInputWrapper span{
    border:1px solid rgba(57,57,57,.15);
    box-sizing:border-box;
    cursor:pointer;
    height:50%;
    line-height:50%;
    opacity:0;
    padding:0 4px 0 2px;
    position:absolute;
    right:0;
    width:14px
}
.numInputWrapper span:hover{
    background:rgba(0,0,0,.1)
}
.numInputWrapper span:active{
    background:rgba(0,0,0,.2)
}
.numInputWrapper span:after{
    content:"";
    display:block;
    position:absolute
}
.numInputWrapper span.arrowUp{
    border-bottom:0;
    top:0
}
.numInputWrapper span.arrowUp:after{
    border-bottom:4px solid rgba(57,57,57,.6);
    border-left:4px solid transparent;
    border-right:4px solid transparent;
    top:26%
}
.numInputWrapper span.arrowDown{
    top:50%
}
.numInputWrapper span.arrowDown:after{
    border-left:4px solid transparent;
    border-right:4px solid transparent;
    border-top:4px solid rgba(57,57,57,.6);
    top:40%
}
.numInputWrapper span svg{
    height:auto;
    width:inherit
}
.numInputWrapper span svg path{
    fill:rgba(0,0,0,.5)
}
.numInputWrapper:hover{
    background:rgba(0,0,0,.05)
}
.numInputWrapper:hover span{
    opacity:1
}
.flatpickr-current-month{
    color:inherit;
    display:inline-block;
    font-size:135%;
    font-weight:300;
    height:34px;
    left:12.5%;
    line-height:inherit;
    line-height:1;
    padding:7.48px 0 0;
    position:absolute;
    text-align:center;
    transform:translateZ(0);
    width:75%
}
.flatpickr-current-month span.cur-month{
    color:inherit;
    display:inline-block;
    font-family:inherit;
    font-weight:700;
    margin-left:.5ch;
    padding:0
}
.flatpickr-current-month span.cur-month:hover{
    background:rgba(0,0,0,.05)
}
.flatpickr-current-month .numInputWrapper{
    display:inline-block;
    width:6ch;
    width:7ch\0
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after{
    border-bottom-color:rgba(0,0,0,.9)
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after{
    border-top-color:rgba(0,0,0,.9)
}
.flatpickr-current-month input.cur-year{
    -webkit-appearance:textfield;
    -moz-appearance:textfield;
    appearance:textfield;
    background:transparent;
    border:0;
    border-radius:0;
    box-sizing:border-box;
    color:inherit;
    cursor:text;
    display:inline-block;
    font-family:inherit;
    font-size:inherit;
    font-weight:300;
    height:auto;
    line-height:inherit;
    margin:0;
    padding:0 0 0 .5ch;
    vertical-align:initial
}
.flatpickr-current-month input.cur-year:focus{
    outline:0
}
.flatpickr-current-month input.cur-year[disabled],.flatpickr-current-month input.cur-year[disabled]:hover{
    background:transparent;
    color:rgba(0,0,0,.5);
    font-size:100%;
    pointer-events:none
}
.flatpickr-current-month .flatpickr-monthDropdown-months{
    appearance:menulist;
    -webkit-appearance:menulist;
    -moz-appearance:menulist;
    background:transparent;
    border:none;
    border-radius:0;
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    color:inherit;
    cursor:pointer;
    font-family:inherit;
    font-size:inherit;
    font-weight:300;
    height:auto;
    line-height:inherit;
    margin:-1px 0 0;
    outline:none;
    padding:0 0 0 .5ch;
    position:relative;
    vertical-align:initial;
    width:auto
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,.flatpickr-current-month .flatpickr-monthDropdown-months:focus{
    outline:none
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover{
    background:rgba(0,0,0,.05)
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{
    background-color:transparent;
    outline:none;
    padding:0
}
.flatpickr-weekdays{
    align-items:center;
    background:transparent;
    display:flex;
    height:28px;
    overflow:hidden;
    text-align:center;
    width:100%
}
.flatpickr-weekdays .flatpickr-weekdaycontainer{
    display:flex;
    flex:1 1
}
span.flatpickr-weekday{
    background:transparent;
    color:rgba(0,0,0,.54);
    cursor:default;
    display:block;
    flex:1 1;
    font-size:90%;
    font-weight:bolder;
    line-height:1;
    margin:0;
    text-align:center
}
.dayContainer,.flatpickr-weeks{
    padding:1px 0 0
}
.flatpickr-days{
    align-items:flex-start;
    display:flex;
    overflow:hidden;
    position:relative;
    width:307.875px
}
.flatpickr-days:focus{
    outline:0
}
.dayContainer{
    box-sizing:border-box;
    display:inline-block;
    display:flex;
    flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    justify-content:space-around;
    max-width:307.875px;
    min-width:307.875px;
    opacity:1;
    outline:0;
    padding:0;
    text-align:left;
    transform:translateZ(0);
    width:307.875px
}
.dayContainer+.dayContainer{
    box-shadow:-1px 0 0 #e6e6e6
}
.flatpickr-day{
    background:none;
    border:1px solid transparent;
    border-radius:150px;
    box-sizing:border-box;
    color:#393939;
    cursor:pointer;
    display:inline-block;
    flex-basis:14.2857143%;
    font-weight:400;
    height:39px;
    justify-content:center;
    line-height:39px;
    margin:0;
    max-width:39px;
    position:relative;
    text-align:center;
    width:14.2857143%
}
.flatpickr-day.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day.nextMonthDay:focus,.flatpickr-day.nextMonthDay:hover,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.today.inRange,.flatpickr-day:focus,.flatpickr-day:hover{
    background:#e6e6e6;
    border-color:#e6e6e6;
    cursor:pointer;
    outline:0
}
.flatpickr-day.today{
    border-color:#959ea9
}
.flatpickr-day.today:focus,.flatpickr-day.today:hover{
    background:#959ea9;
    border-color:#959ea9;
    color:#fff
}
.flatpickr-day.endRange,.flatpickr-day.endRange.inRange,.flatpickr-day.endRange.nextMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.endRange:focus,.flatpickr-day.endRange:hover,.flatpickr-day.selected,.flatpickr-day.selected.inRange,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.selected:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange,.flatpickr-day.startRange.inRange,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.startRange:focus,.flatpickr-day.startRange:hover{
    background:#569ff7;
    border-color:#569ff7;
    box-shadow:none;
    color:#fff
}
.flatpickr-day.endRange.startRange,.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange{
    border-radius:50px 0 0 50px
}
.flatpickr-day.endRange.endRange,.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange{
    border-radius:0 50px 50px 0
}
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)){
    box-shadow:-10px 0 0 #569ff7
}
.flatpickr-day.endRange.startRange.endRange,.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange{
    border-radius:50px
}
.flatpickr-day.inRange{
    border-radius:0;
    box-shadow:-5px 0 0 #e6e6e6,5px 0 0 #e6e6e6
}
.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.nextMonthDay,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.prevMonthDay{
    background:transparent;
    border-color:transparent;
    color:rgba(57,57,57,.3);
    cursor:default
}
.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover{
    color:rgba(57,57,57,.1);
    cursor:not-allowed
}
.flatpickr-day.week.selected{
    border-radius:0;
    box-shadow:-5px 0 0 #569ff7,5px 0 0 #569ff7
}
.flatpickr-day.hidden{
    visibility:hidden
}
.rangeMode .flatpickr-day{
    margin-top:1px
}
.flatpickr-weekwrapper{
    float:left
}
.flatpickr-weekwrapper .flatpickr-weeks{
    box-shadow:1px 0 0 #e6e6e6;
    padding:0 12px
}
.flatpickr-weekwrapper .flatpickr-weekday{
    float:none;
    line-height:28px;
    width:100%
}
.flatpickr-weekwrapper span.flatpickr-day,.flatpickr-weekwrapper span.flatpickr-day:hover{
    background:transparent;
    border:none;
    color:rgba(57,57,57,.3);
    cursor:default;
    display:block;
    max-width:none;
    width:100%
}
.flatpickr-innerContainer{
    box-sizing:border-box;
    display:block;
    display:flex;
    overflow:hidden
}
.flatpickr-rContainer{
    box-sizing:border-box;
    display:inline-block;
    padding:0
}
.flatpickr-time{
    box-sizing:border-box;
    display:block;
    display:flex;
    height:0;
    line-height:40px;
    max-height:40px;
    outline:0;
    overflow:hidden;
    text-align:center
}
.flatpickr-time:after{
    clear:both;
    content:"";
    display:table
}
.flatpickr-time .numInputWrapper{
    flex:1 1;
    float:left;
    height:40px;
    width:40%
}
.flatpickr-time .numInputWrapper span.arrowUp:after{
    border-bottom-color:#393939
}
.flatpickr-time .numInputWrapper span.arrowDown:after{
    border-top-color:#393939
}
.flatpickr-time.hasSeconds .numInputWrapper{
    width:26%
}
.flatpickr-time.time24hr .numInputWrapper{
    width:49%
}
.flatpickr-time input{
    -webkit-appearance:textfield;
    -moz-appearance:textfield;
    appearance:textfield;
    background:transparent;
    border:0;
    border-radius:0;
    box-shadow:none;
    box-sizing:border-box;
    color:#393939;
    font-size:14px;
    height:inherit;
    line-height:inherit;
    margin:0;
    padding:0;
    position:relative;
    text-align:center
}
.flatpickr-time input.flatpickr-hour{
    font-weight:700
}
.flatpickr-time input.flatpickr-minute,.flatpickr-time input.flatpickr-second{
    font-weight:400
}
.flatpickr-time input:focus{
    border:0;
    outline:0
}
.flatpickr-time .flatpickr-am-pm,.flatpickr-time .flatpickr-time-separator{
    align-self:center;
    color:#393939;
    float:left;
    font-weight:700;
    height:inherit;
    line-height:inherit;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
    width:2%
}
.flatpickr-time .flatpickr-am-pm{
    cursor:pointer;
    font-weight:400;
    outline:0;
    text-align:center;
    width:18%
}
.flatpickr-time .flatpickr-am-pm:focus,.flatpickr-time .flatpickr-am-pm:hover,.flatpickr-time input:focus,.flatpickr-time input:hover{
    background:#eee
}
.flatpickr-input[readonly]{
    cursor:pointer
}
@-webkit-keyframes fpFadeInDown{
    0%{
        opacity:0;
        transform:translate3d(0,-20px,0)
    }
    to{
        opacity:1;
        transform:translateZ(0)
    }
}
@keyframes fpFadeInDown{
    0%{
        opacity:0;
        transform:translate3d(0,-20px,0)
    }
    to{
        opacity:1;
        transform:translateZ(0)
    }
}


@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
:root{
    --bsk-display-1-font-size:5rem;
    --bsk-display-2-font-size:4.5rem;
    --bsk-display-3-font-size:4rem;
    --bsk-display-4-font-size:3.5rem;
    --bsk-display-5-font-size:3rem;
    --bsk-display-6-font-size:2.5rem;
    --bsk-h1-font-size:3rem;
    --bsk-h2-font-size:2.5rem;
    --bsk-h3-font-size:2rem;
    --bsk-h4-font-size:1.5rem;
    --bsk-h5-font-size:1.2rem;
    --bsk-h6-font-size:1rem;
    --bsk-spacer-0:0rem;
    --bsk-spacer-1:0.125rem;
    --bsk-spacer-2:0.3125rem;
    --bsk-spacer-3:0.625rem;
    --bsk-spacer-4:0.9375rem;
    --bsk-spacer-5:1.25rem;
    --bsk-spacer-6:1.875rem;
    --bsk-spacer-7:2.5rem;
    --bsk-spacer-8:4.75rem;
    --bsk-spacer-9:6.255rem;
    --bsk-spacer-10:7.5rem;
    --bsk-border-radius:0rem;
    --bsk-border-radius-sm:0rem;
    --bsk-border-radius-lg:0rem;
    --bsk-border-radius-pill:0rem;
    --bsk-box-shadow:0 0.5rem 1rem rgba(0,0,0,.15);
    --bsk-box-shadow-sm:0 0.125rem 0.25rem rgba(0,0,0,.075);
    --bsk-box-shadow-lg:0 1rem 3rem rgba(0,0,0,.175);
    --bsk-box-shadow-inset:inset 0 1px 2px rgba(0,0,0,.075);
    --bsk-font-family-base:var(--bs-font-sans-serif);
    --bsk-headings-font-family:Open Sans
}
header{
    background:#fff;
    color:#fff;
    font-weight:500;
    left:0;
    position:fixed;
    top:0;
    transition:.5s;
    width:100%;
    z-index:100
}
header a,header button{
    color:#fff;
    outline:none;
    text-decoration:none
}
header .navbar-brand__img{
    filter:invert(0)
}
header .search-input{
    background:none;
    border:none;
    border-bottom:1px solid hsla(0,0%,100%,.5);
    color:#fff;
    font-size:1rem;
    line-height:31px;
    outline:none;
    transition:.5s;
    width:250px
}
header .search-input::-moz-placeholder{
    color:#fff
}
header .search-input::placeholder{
    color:#fff
}
.solid-header header,header:hover{
    background:#fff;
    color:#000
}
.solid-header header .navbar-brand__img,header:hover .navbar-brand__img{
    filter:invert(1)
}
.solid-header header a,.solid-header header button,header:hover a,header:hover button{
    color:#000;
    outline:none;
    text-decoration:none
}
.solid-header header .search-input,header:hover .search-input{
    border-bottom:1px solid rgba(0,0,0,.5);
    color:#000
}
.solid-header header .search-input::-moz-placeholder,header:hover .search-input::-moz-placeholder{
    color:#000
}
.solid-header header .search-input::placeholder,header:hover .search-input::placeholder{
    color:#000
}
.solid-header header{
    position:static
}
header .navbar-logos{
    display:inline-flex
}
#header-navigation{
    overflow:scroll
}
#header-navigation .btn-close{
    height:100vh;
    left:340px;
    opacity:0;
    position:absolute;
    top:0;
    width:calc(100vw - 340px)
}
#header-navigation .dropdown-item svg,#header-navigation .nav-item svg{
    color:#212529;
    padding-right:12px;
    width:18px
}
#header-navigation .dropdown-item:has(img),#header-navigation .dropdown-item:has(svg),#header-navigation .nav-link:has(img),#header-navigation .nav-link:has(svg){
    align-items:center;
    display:flex;
    justify-content:space-between
}
#header-navigation .dropdown-item:hover svg,#header-navigation .nav-link:hover svg{
    color:#000
}
#header-navigation .offcanvas-body>.navbar-nav>.nav-item>.nav-link{
    font-weight:700
}
#header-navigation .nav-link{
    color:#212529;
    display:flex;
    justify-content:space-between;
    padding:.75rem 10px;
    position:relative;
    transition:.5s
}
#header-navigation .nav-link.show:after{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f1011' stroke-width='2'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")!important
}
#header-navigation .nav-link.dropdown-toggle:after{
    align-items:center;
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='3' stroke-width='2'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-position:50%;
    background-repeat:no-repeat;
    background-size:100% 100%;
    border:none;
    display:inline-flex;
    font-size:1.5rem;
    height:1.25rem;
    position:absolute;
    right:1.3rem;
    top:50%;
    transform:rotate(0deg) translateY(-50%);
    transform-origin:center;
    transition:transform .4s ease;
    width:1.25rem
}
#header-navigation a:hover{
    background-color:#e9ecef!important;
    color:#000!important
}
#header-navigation a:hover:after{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f1011' stroke-width='2'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")!important
}
#header-navigation .dropdown-item,#header-navigation .dropdown-item.dropdown-toggle{
    color:#000;
    font-weight:500;
    padding:.75rem 0;
    position:relative;
    white-space:normal
}
#header-navigation .dropdown-item.dropdown-toggle:hover,#header-navigation .dropdown-item:hover{
    background-color:transparent
}
#header-navigation .dropdown-item.dropdown-toggle.show,#header-navigation .dropdown-item.show{
    color:#000
}
#header-navigation .dropdown-item.dropdown-toggle.show:after,#header-navigation .dropdown-item.show:after{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f1011' stroke-width='2'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}
#header-navigation .dropdown-item.dropdown-toggle:after{
    align-items:center;
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor' stroke-width='2'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-position:50%;
    background-repeat:no-repeat;
    background-size:100% 100%;
    border:none;
    display:inline-flex;
    font-size:1.5rem;
    height:1rem;
    position:absolute;
    right:1.3rem;
    top:50%;
    transform:rotate(0deg) translateY(-50%);
    transform-origin:center;
    transition:transform .4s ease;
    width:1rem
}
#header-navigation .nav-link.dropdown-toggle.show{
    color:#000
}
#header-navigation .dropdown-menu{
    border:none;
    border-radius:0;
    position:static!important;
    transform:none!important
}
#header-navigation .nav-item:last-child .dropdown-menu.show{
    overflow:auto
}
#header-navigation ul{
    list-style:none
}
.nav-franchise-img-wrapper{
    display:flex;
    justify-content:center;
    width:72px
}
.navbar--header-main .navbar-toggler{
    border:0;
    padding:0
}
.offcanvas-bottom{
    height:60vh!important;
    overflow:auto
}
.offcanvas-bottom .offcanvas-header{
    position:sticky;
    top:0
}
a.navbar-toggler:focus{
    box-shadow:none
}
.header-notification{
    background:#000;
    color:#fff;
    display:none;
    font-weight:500;
    padding:0 2rem;
    position:relative;
    text-align:center
}
.header-notification a{
    color:#fff!important
}
.header-notification p{
    margin:.5rem 0
}
.header-notification .notification__close{
    color:#fff;
    font-size:1.2rem;
    position:absolute;
    right:2rem;
    text-decoration:none;
    top:.5rem
}
.btn-black-close{
    background-color:#000;
    border:0;
    border-radius:5px;
    display:flex;
    height:46px;
    justify-content:center;
    position:relative;
    width:46px
}
.btn-black-close:after{
    transform:rotate(45deg)
}
.btn-black-close:after,.btn-black-close:before{
    background-color:#fff;
    content:"";
    height:23px;
    position:absolute;
    top:25%;
    width:2px
}
.btn-black-close:before{
    transform:rotate(-45deg)
}
main{
    padding-top:5rem
}
.dealer-nav-item .dealer-img{
    aspect-ratio:18/9;
    margin-bottom:10px;
    -o-object-fit:cover;
    object-fit:cover
}
.dealer-nav-item .dropdown-item{
    background-color:#e9ecef;
    margin-top:10px
}
/*! * Hamburgers * @description Tasty CSS-animated hamburgers * @author Jonathan Suh @jonsuh * @site https://jonsuh.com/hamburgers * @link https://github.com/jonsuh/hamburgers */
.hamburger{
    background-color:transparent;
    border:0;
    color:inherit;
    cursor:pointer;
    display:inline-block;
    font:inherit;
    margin:0;
    overflow:visible;
    padding:15px;
    text-transform:none;
    transition-duration:.15s;
    transition-property:opacity,filter;
    transition-timing-function:linear
}
.hamburger:hover,.hamburger[aria-expanded=true]:hover{
    opacity:.7
}
.hamburger[aria-expanded=true] .hamburger-inner,.hamburger[aria-expanded=true] .hamburger-inner:after,.hamburger[aria-expanded=true] .hamburger-inner:before{
    background-color:#000;
    border-radius:10px
}
.hamburger-box{
    display:inline-block;
    height:23px;
    position:relative;
    width:30px
}
.hamburger-inner{
    display:block;
    margin-top:-1.5px;
    top:50%
}
.hamburger-inner,.hamburger-inner:after,.hamburger-inner:before{
    background-color:#000;
    border-radius:10px;
    height:3px;
    position:absolute;
    transition-duration:.15s;
    transition-property:transform;
    transition-timing-function:ease;
    width:30px
}
.hamburger-inner:after,.hamburger-inner:before{
    content:"";
    display:block
}
.hamburger-inner:before{
    top:-10px
}
.hamburger-inner:after{
    bottom:-10px
}
.hamburger--spin .hamburger-inner{
    transition-duration:.22s;
    transition-timing-function:cubic-bezier(.55,.055,.675,.19)
}
.hamburger--spin .hamburger-inner:before{
    transition:top .1s ease-in .25s,opacity .1s ease-in
}
.hamburger--spin .hamburger-inner:after{
    transition:bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19)
}
.hamburger--spin[aria-expanded=true] .hamburger-inner{
    transform:rotate(225deg);
    transition-delay:.12s;
    transition-timing-function:cubic-bezier(.215,.61,.355,1)
}
.hamburger--spin[aria-expanded=true] .hamburger-inner:before{
    opacity:0;
    top:0;
    transition:top .1s ease-out,opacity .1s ease-out .12s
}
.hamburger--spin[aria-expanded=true] .hamburger-inner:after{
    bottom:0;
    transform:rotate(-90deg);
    transition:bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s
}
.bg-image{
    background-color:#212529;
    background-position:50%;
    background-repeat:no-repeat;
    background-size:cover;
    color:#fff;
    padding:80px 10px;
    position:relative
}
.bg-image:before{
    background-color:rgba(33,37,41,.4);
    content:"";
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%
}
@supports not (-webkit-touch-callout:none){
    .bg-image{
        background-attachment:fixed
    }
}
/* .bg-continue-search{
    background-image:url(/assets/img/backgrounds/continue-search.jpg)
} */
.featured-car img{
    aspect-ratio:3/2;
    max-width:100%;
    -o-object-fit:cover;
    object-fit:cover
}
.featured-car .card-subtitle,.featured-car .card-title{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
#map-canvas{
    height:320px
}
.nav-segment{
    background-color:#f8fafd;
    border-radius:.3125rem;
    padding:.25rem;
    position:relative
}
.nav-segment:not(.nav-fill){
    display:inline-flex
}
.nav-segment .nav-link{
    border-radius:.3125rem;
    color:#677788;
    font-size:.875rem;
    font-weight:700;
    padding:.375rem .875rem
}
.nav-segment .nav-link.active{
    background-color:#fff;
    box-shadow:0 .125rem .25rem rgba(0,0,0,.075);
    color:#212529
}
.reg-plate{
    background-color:#eace00;
    /* background-image:url(https://bluesky.sirv.com/Global/Assets/Images/Components/Numberplate/numberplate-bg.gif); */
    background-repeat:no-repeat;
    background-size:contain;
    border:2px solid #dee2e6;
    font-family:uknumberplateregular;
    font-size:2em;
    max-width:8em;
    padding-bottom:.1em;
    padding-left:2.1em;
    padding-top:.1em;
    position:relative;
    text-transform:uppercase
}
.reg-plate--white{
    background-color:#fff
}
.reg-plate--transparent{
    background-color:transparent
}
.reg-plate--inline{
    font-size:1em;
    max-width:unset;
    padding-bottom:.5em;
    padding-left:2.6em;
    padding-top:.5em
}
.slick-slide img{
    height:auto;
    max-width:100%
}
.slider .carousel-control-next,.slider .carousel-control-prev,.slider .slick-next,.slider .slick-prev,.slider .slider-next,.slider .slider-prev{
    bottom:auto;
    top:50%;
    transform:translateY(-50%)
}
.slick-slider .slick-slide{
    margin:0 19px
}
.slick-slider .slick-dots{
    margin:25px auto 1em;
    position:relative
}
.slick-slider .slick-dots li button{
    background-color:#6c757d;
    border-radius:50%;
    height:15px;
    opacity:1;
    width:15px
}
.slick-slider .slick-dots li.slick-active button{
    background-color:#f8ba05
}
.page-header{
    background-color:#212529;
    margin-bottom:80px;
    position:relative
}
.page-header:before{
    background-color:rgba(0,0,0,.3);
    content:"";
    inset:0;
    position:absolute;
    z-index:1
}
.page-header img{
    height:100%;
    inset:0;
    -o-object-fit:cover;
    object-fit:cover;
    position:absolute;
    width:100%
}
.page-header .page-header__content{
    color:#fff;
    padding:80px 1.5rem;
    position:relative;
    text-align:center;
    z-index:1
}
.page-header .page-header__content h1{
    font-size:2.5rem;
    font-weight:700;
    margin-bottom:20px
}
.page-header .page-header__content p{
    font-size:1.5rem;
    font-weight:400
}
.footer--centered>div[class*=container]{
    gap:.75rem
}
.footer .footer__links li:last-of-type{
    margin-bottom:0
}
.footer .footer__content,.footer .footer__links,.footer div[class*=col-]>[slot]:not(:has(:is(.footer__links,.footer__content))){
    display:flex;
    flex-direction:column
}
.footer table td{
    padding-bottom:.75rem
}
.footer img{
    max-width:200px;
    width:100%
}
.footer address{
    font-size:1rem
}
.footer ul{
    list-style:none;
    margin-bottom:2rem;
    padding-left:0
}
.footer ul li{
    font-size:1rem;
    margin-bottom:.75rem
}
.footer a,.footer a:hover{
    color:#000
}
.footer .primary-links{
    gap:.75rem
}
.footer .footer-copyright a{
    color:#fff
}
[v-cloak]{
    display:none
}
.spotlight{
    aspect-ratio:1.9/1;
    overflow:hidden;
    position:relative
}
.spotlight:hover .spotlight__image{
    transform:scale(1.1)
}
.spotlight:hover .spotlight__image:before{
    background:rgba(0,0,0,.6)
}
.spotlight .spotlight__image{
    height:100%;
    transition:transform .3s ease-in-out
}
.spotlight .spotlight__image:before{
    background:rgba(0,0,0,.4);
    content:"";
    height:100%;
    inset:0;
    position:absolute;
    transition:background .3s ease-in-out;
    width:100%;
    z-index:1
}
.spotlight .spotlight__image img{
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
.spotlight .spotlight__headline{
    color:#fff;
    font-size:1.5rem;
    font-weight:700;
    left:0;
    padding:1rem;
    position:absolute;
    text-align:center;
    top:50%;
    transform:translateY(-50%);
    width:100%;
    z-index:2
}
.testimonial{
    display:block!important;
    height:auto
}
.testimonial img{
    -o-object-fit:cover;
    object-fit:cover
}
.testimonial .clamp{
    -webkit-line-clamp:5;
    -webkit-box-orient:vertical;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis
}
.testimonial .fa-star path{
    fill:#ffda6a
}
.lightbox{
    text-decoration:none
}
.lightbox iframe{
    aspect-ratio:16/9;
    border-radius:10px;
    height:100%;
    min-height:140px;
    -o-object-fit:cover;
    object-fit:cover;
    position:relative;
    width:100%
}
.lightbox picture{
    display:block;
    position:relative
}
.lightbox picture img{
    border-radius:10px;
    height:100%;
    min-height:140px;
    -o-object-fit:cover;
    object-fit:cover;
    position:relative;
    width:100%
}
.lightbox picture .blurred{
    filter:blur(60px) saturate(4) brightness(1.2);
    inset:0;
    opacity:.6;
    position:absolute
}
.lightbox .lightbox__content{
    position:relative;
    z-index:1
}
.fancybox-content{
    border-radius:10px;
    max-width:800px;
    padding:30px
}
.team-member{
    align-items:center;
    display:flex;
    flex-direction:column;
    height:100%;
    margin:0 auto;
    max-width:840px
}
.team-member img{
    aspect-ratio:1;
    border:10px solid #fff;
    border-radius:50%;
    height:auto;
    max-width:380px;
    -o-object-fit:cover;
    object-fit:cover;
    -o-object-position:0 20%;
    object-position:0 20%;
    width:100%
}
#testimonial-slider{
    background:var(--testimonial-background) no-repeat;
    background-color:#212529;
    background-size:cover;
    overflow:hidden
}
#testimonial-slider:before{
    background-color:#000;
    content:"";
    inset:0;
    opacity:.8;
    position:absolute;
    z-index:1
}
#testimonial-slider>div{
    z-index:2
}
@supports not (-webkit-touch-callout:none){
    #testimonial-slider{
        background-attachment:fixed
    }
}
.btn{
    font-weight:700!important;
    text-transform:uppercase
}
#get-in-touch #CallbackOrEmailToggleLabel{
    display:flex;
    gap:10px;
    justify-content:center
}
#get-in-touch #CallbackOrEmailToggleLabel .toggle{
    border:1px solid #000;
    border-radius:40px;
    cursor:pointer;
    position:relative;
    width:41px
}
#get-in-touch #CallbackOrEmailToggleLabel .toggle:before{
    border:1px solid #000;
    border-radius:50%;
    content:"";
    height:20px;
    left:2px;
    position:absolute;
    top:3px;
    transition:.3s;
    width:20px
}
#get-in-touch #CallbackOrEmailToggleLabel #CallbackOrEmailToggleInput:checked~span:last-child,#get-in-touch #CallbackOrEmailToggleLabel #CallbackOrEmailToggleInput:not(:checked)+span{
    font-weight:700
}
#get-in-touch #CallbackOrEmailToggleLabel #CallbackOrEmailToggleInput:checked+span{
    font-weight:400
}
#get-in-touch #CallbackOrEmailToggleLabel #CallbackOrEmailToggleInput:checked+span+.toggle:before{
    left:16px
}
.vehicle-card img{
    height:110px;
    width:auto
}
.news-article .w-fit-content{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content
}
.news-article.v1 img{
    height:200px;
    -o-object-fit:cover;
    object-fit:cover
}
.news-article.v1.featured{
    margin-bottom:0!important
}
.news-article.v1.featured img{
    height:100%;
    -o-object-fit:cover;
    object-fit:cover
}
.news-article.v1 .card,.news-article.v1 .card .card-content{
    height:100%
}
.news-article.v1 .card .card-content .card-body{
    display:flex;
    flex-direction:column;
    height:100%
}
.news-article.v1 .card .card-content .card-body .card-text{
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    display:-webkit-box;
    height:4.5em;
    overflow:hidden;
    text-overflow:ellipsis
}
.news-article.v1 .card .card-content .card-body .btn{
    margin-top:auto;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content
}
.news-article.v3 .card-body{
    padding:2rem!important;
    transition:.5s
}
.news-article.v3 .news-synopsis{
    align-items:center;
    bottom:-100%;
    color:#fff;
    display:flex;
    font-size:14px;
    height:100%;
    line-height:1.3;
    padding:2rem;
    position:absolute;
    transition:.5s;
    width:100%
}
.news-article.v3:hover .news-synopsis{
    bottom:0
}
.news-article.v3:hover .card-body{
    bottom:100%
}
.news-article.v3.featured .card{
    height:100%
}
.news.page .pagination{
    justify-content:center
}
.news.page .slick-slider .slick-slide{
    margin:0
}
.news-details .section{
    padding:80px 0
}
.news-details .section .related-articles{
    max-width:1420px
}
.news-details .related-articles-carousel .slick-list,.news-details .related-articles-carousel .slick-track{
    display:flex
}
.news-details .related-articles-carousel .slick-track .slick-slide,.news-details .related-articles-carousel .slick-track .slick-slide>div,.news-details .related-articles-carousel .slick-track .slick-slide>div article{
    height:100%
}
.thankyou .thankyou-content{
    margin-top:120px
}
.dealer__welcome .umb-grid .container{
    padding:0
}
.dealership__hours{
    border-bottom:1px solid #ced4da;
    padding-bottom:47px;
    padding-top:60px
}
.dealership__hours .opening-hours-table-wrapper{
    padding-top:47px
}
.dealership__hours .opening-hours-table-wrapper>.container{
    max-width:unset
}
.dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table{
    margin-right:10px
}
.dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table table{
    border:none;
    font-size:1.125rem;
    margin-bottom:20px
}
.dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table table tr{
    border:none
}
.dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table table tr td{
    border:none;
    line-height:1
}
.dealership__stock{
    margin-bottom:80px;
    margin-top:80px
}
.ucd-ctas-group{
    grid-gap:.5rem;
    display:grid;
    grid-template-columns:1fr
}
.ucd-ctas-group .btn{
    margin:auto;
    max-width:28rem;
    width:100%
}
span:has([id*=VehicleViewCount]:empty){
    display:none
}
#request-vehicle select option:disabled{
    color:#e0e0e0;
    display:none
}
.uvd .flatpickr-input{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 1v1h4V1c0-.531.438-1 1-1 .531 0 1 .469 1 1v1H13c.813 0 1.5.688 1.5 1.5V5H.5V3.5A1.5 1.5 0 0 1 2 2h1.5V1c0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-5 5h14v8.5A1.5 1.5 0 0 1 13 16H2a1.48 1.48 0 0 1-1.5-1.5V6Zm2 3.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5H3c-.281 0-.5.25-.5.5v1Zm4 0c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5H7c-.281 0-.5.25-.5.5v1ZM11 8c-.281 0-.5.25-.5.5v1c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5h-1Zm-8.5 5.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5H3c-.281 0-.5.25-.5.5v1ZM7 12c-.281 0-.5.25-.5.5v1c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5H7Zm3.5 1.5c0 .281.219.5.5.5h1c.25 0 .5-.219.5-.5v-1c0-.25-.25-.5-.5-.5h-1c-.281 0-.5.25-.5.5v1Z' fill='%23000' fill-opacity='.5'/%3E%3C/svg%3E");
    background-position:right 1.125rem center;
    background-repeat:no-repeat;
    background-size:14px 18px;
    padding:.8125rem 3.375rem .8125rem 1.125rem
}
.home .home-banner .banner{
    position:relative
}
.home .home-banner .banner a{
    text-decoration:none
}
.home .home-banner .banner:hover .banner__image:after{
    opacity:.5
}
.home .home-banner .banner:hover .banner__image img{
    transform:scale(1.1)
}
.home .home-banner .banner .banner__image{
    inset:0;
    overflow:hidden;
    position:absolute;
    width:100%
}
.home .home-banner .banner .banner__image:after{
    background:#000;
    content:"";
    inset:0;
    opacity:.3;
    position:absolute;
    transition:opacity .5s ease
}
.home .home-banner .banner .banner__image img{
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    transition:transform .5s ease;
    width:100%
}
.home .home-banner .banner__content{
    padding:100px 10px 60px;
    position:relative;
    text-align:center;
    width:100%;
    z-index:2
}
.container .umb-grid .container{
    padding-left:0;
    padding-right:0
}
.container-wrapper-sm>.container{
    max-width:880px
}
.container-wrapper-md>.container{
    max-width:1030px
}
.container-wrapper-lg>.container{
    max-width:1420px
}
.container-wrapper-fluid>.container{
    margin:0 auto;
    max-width:100%
}
.umb-grid .grid-section>*{
    padding:1rem 0
}
.umb-grid iframe{
    aspect-ratio:16/9;
    height:auto;
    max-width:100%;
    width:100%
}
.umb-grid p{
    line-height:1.5em
}
.umb-grid img{
    height:auto;
    max-width:100%;
    width:100%
}
.umb-grid .image-caption{
    color:#212529;
    font-weight:600;
    margin:0 0 1.5rem;
    text-transform:capitalize
}
.umb-grid .image-with-accreditation-wrapper{
    margin-bottom:1rem
}
.umb-grid .grid-section>.container{
    padding:0
}
.umb-grid .accordion>.card{
    word-wrap:break-word;
    background-clip:border-box;
    background-color:#e9ecef;
    border:none;
    border-radius:.25rem;
    color:#000;
    display:flex;
    flex-direction:column;
    min-width:0;
    overflow:hidden;
    position:relative
}
.umb-grid .accordion>.card:first-of-type{
    border-bottom:0;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0
}
.umb-grid .accordion>.card:last-of-type{
    border-bottom:1px solid #e9ecef;
    border-top-left-radius:0;
    border-top-right-radius:0
}
.umb-grid .accordion>.card:not(:first-of-type) .card-header:first-child{
    border-radius:0
}
.umb-grid .accordion>.card .card-header{
    background-color:#e9ecef;
    border-bottom:1px solid #e9ecef;
    color:#212529;
    margin-bottom:0;
    padding:.75rem 1.25rem;
    position:relative
}
.umb-grid .accordion>.card .card-header:first-child{
    border-radius:calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}
.umb-grid .accordion>.card .card-header .btn-link{
    color:#212529;
    margin-bottom:0;
    padding-left:2rem;
    text-align:left;
    text-decoration:none;
    width:100%
}
.umb-grid .accordion>.card .card-header .btn-link:focus{
    box-shadow:none
}
.umb-grid .accordion>.card .card-header button:after{
    align-items:center;
    background-color:#212529;
    content:"";
    display:flex;
    height:100%;
    justify-content:center;
    -webkit-mask:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size:1.2rem;
    mask-size:1.2rem;
    position:absolute;
    right:1.5rem;
    top:0;
    transition:.15s;
    width:40px
}
.umb-grid .accordion>.card .card-header button[aria-expanded=true]:after{
    transform:rotate(180deg)
}
.umb-grid .accordion>.card .card-body{
    background-color:#fff;
    flex:1 1 auto;
    padding:1.25rem 1.875rem
}
.responsive-image__large,.responsive-image__medium{
    display:none
}
.video-wrapper{
    display:block;
    margin-bottom:30px;
    overflow:hidden;
    padding:0;
    position:relative;
    width:100%
}
.video-wrapper:before{
    content:"";
    display:block;
    padding-top:56.25%
}
.video-wrapper iframe{
    border:0;
    border-radius:10px;
    bottom:0;
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:100%
}
.full-gallery-wrap{
    margin-bottom:30px
}
.gallery-list-inner,.image-gallery{
    margin:20px 0
}
.gallery-list-inner .image-gallery__ul,.image-gallery .image-gallery__ul{
    display:flex;
    flex-wrap:wrap;
    list-style:none;
    margin:0;
    padding:0
}
.gallery-list-inner .image-gallery__ul .gallery-item-container,.image-gallery .image-gallery__ul .gallery-item-container{
    width:100%
}
.gallery-list-inner .image-gallery__ul .gallery-item,.image-gallery .image-gallery__ul .gallery-item{
    background-position:50%;
    background-size:cover;
    display:block;
    height:300px;
    max-width:100%;
    width:400px
}
.umb-grid blockquote{
    border:1px solid #c3c3c3;
    padding:30px;
    text-align:center
}
.umb-grid blockquote p{
    font-size:1.3rem;
    margin-bottom:.75rem
}
.umb-grid blockquote cite{
    font-style:normal;
    font-weight:700
}
.text-align-center{
    text-align:center
}
.text-align-left{
    text-align:left
}
.text-align-right{
    text-align:right
}
.tl-grid-cards__row{
    display:grid;
    flex-wrap:wrap;
    gap:20px;
    justify-content:center
}
.tl-grid-cards__row .tl-grid-cards__item{
    border:1px solid #dee2e6;
    border-radius:0;
    box-shadow:0 .5rem 1rem rgba(0,0,0,.15);
    margin-bottom:1rem;
    overflow:hidden;
    width:100%
}
.tl-grid-cards__row .tl-grid-cards__item:has(a:hover) .tl-grid-card__image img{
    transform:scale(1)
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__image{
    aspect-ratio:16/9;
    overflow:hidden
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__image img{
    aspect-ratio:16/9;
    -o-object-fit:cover;
    object-fit:cover;
    transform:scale(1.05);
    transition:transform .3s ease
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__body{
    padding:1.5rem 2rem;
    text-align:center
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__body h3{
    font-size:1.5rem
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__body p{
    margin-bottom:2rem
}
.tl-grid-cards__row .tl-grid-cards__item .tl-grid-card__body a{
    font-weight:700;
    text-decoration:none
}
.cms-row-to-cards img{
    aspect-ratio:2.11;
    filter:brightness(.6);
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
.cms-row-to-cards .image-with-accreditation-wrapper{
    margin-bottom:1.875rem
}
.cms-row-to-cards h3{
    font-weight:700
}
.cms-row-to-cards ul{
    list-style:inside;
    padding-left:0
}
.cms-row-to-cards ul li{
    line-height:1.5
}
.grid-vew .grid-container{
    width:100%
}
.proxy-list li{
    margin-bottom:.25em
}
.proxy-list li span{
    align-items:center;
    display:inline-flex
}
.proxy-list li.proxy-black span:before{
    background-color:#000
}
.proxy-list li.proxy-blue span:before{
    background-color:#03c
}
.proxy-list li.proxy-bronze span:before{
    background-color:#c90
}
.proxy-list li.proxy-brown span:before{
    background-color:#630
}
.proxy-list li.proxy-gold span:before{
    background-color:#c93
}
.proxy-list li.proxy-green span:before{
    background-color:#090
}
.proxy-list li.proxy-grey span:before{
    background-color:#999
}
.proxy-list li.proxy-orange span:before{
    background-color:#f60
}
.proxy-list li.proxy-purple span:before{
    background-color:#639
}
.proxy-list li.proxy-red span:before{
    background-color:#c00
}
.proxy-list li.proxy-yellow span:before{
    background-color:#ff0
}
.proxy-list li.proxy-silver span:before{
    background-color:#ccc
}
.proxy-list li.proxy-white span:before{
    background-color:#fff;
    border:1px solid #aeaeae
}
.proxy-list li.proxy-unknown:after{
    color:#3c5866;
    content:"?";
    font-family:Open Sans,Arial,Helvetica,sans-serif;
    font-weight:700;
    position:absolute;
    right:10px;
    text-align:center;
    top:7px
}
.colour-group .proxy-list li span:before{
    border-radius:50%;
    content:"";
    display:inline-block;
    height:1em;
    margin-right:.25em;
    width:1em
}
/* @font-face{
    font-family:uknumberplateregular;
    font-style:normal;
    font-weight:400;
    src:url(/assets/fonts/UKNumberPlate/UKNumberPlate-webfont.woff2) format("woff2"),url(/assets/fonts/UKNumberPlate/UKNumberPlate-webfont.woff) format("woff"),url(/assets/fonts/UKNumberPlate/UKNumberPlate-webfont.ttf) format("truetype"),url(/assets/fonts/UKNumberPlate/UKNumberPlate-webfont.svg#uknumberplateregular) format("svg")
} */
.fw-semi,.fw-semibold{
    font-weight:600!important
}
h1,h2,h3,h4,h5,h6{
    text-wrap:pretty;
    text-transform:uppercase
}
.ajax__calendar{
    display:none!important
}
.inline-icon-img{
    display:inline-block;
    height:1em
}
.flex-c{
    flex-direction:row
}
.flex-c,.flex-c-col{
    align-items:center;
    display:flex;
    justify-content:center
}
.flex-c-col{
    flex-direction:column
}
@media (min-width:768px){
    .dealership__hours .opening-hours-table-wrapper>.container .row .column{
        padding:0 10px
    }
    .dealership__hours .opening-hours-table-wrapper>.container .row .column:not(:last-child) .opening-hours-table{
        border-right:1px solid #ced4da
    }
    .dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table table{
        margin-bottom:0
    }
    .responsive-image__small{
        display:none
    }
    .gallery-list-inner .image-gallery__ul .gallery-item-container,.image-gallery .image-gallery__ul .gallery-item-container{
        width:50%
    }
    .tl-grid-cards__row{
        grid-template-columns:repeat(2,1fr)
    }
}
@media (min-width:768px)and (max-width:991.98px){
    .responsive-image__medium{
        display:block
    }
}
@media only screen and (min-width:768px){
    .umb-grid .grid-section>*{
        padding:2rem 0
    }
}
@media (min-width:992px){
    #header-navigation.offcanvas-start{
        width:340px
    }
    #header-navigation li.h6{
        padding-left:10px
    }
    #header-navigation .dropdown-item,#header-navigation .dropdown-item.dropdown-toggle{
        font-size:.94rem;
        padding-left:10px
    }
    .dealer-nav-item .dropdown-item{
        font-size:.94rem
    }
    .news-article.v1.featured img{
        height:440px
    }
    .news-article.v3.featured .card{
        height:605px
    }
    .news-article.v3.featured .card .news-synopsis{
        font-size:1rem;
        line-height:1.5
    }
    .dealership__hours .opening-hours-table-wrapper>.container .row .column .opening-hours-table{
        margin-right:30px
    }
    .home .home-banner .banner__content{
        padding-top:205px
    }
    .responsive-image__large{
        display:block
    }
    .gallery-list-inner .image-gallery__ul .gallery-item-container,.image-gallery .image-gallery__ul .gallery-item-container{
        width:33.33333%
    }
    .tl-grid-cards__row{
        grid-template-columns:repeat(3,1fr)
    }
}
@media only screen and (min-width:992px){
    #header-navigation{
        overflow:inherit
    }
    #header-navigation .dropdown-menu{
        background:#fff;
        border:none;
        border-radius:0;
        height:100vh;
        left:0!important;
        padding:.75rem;
        position:fixed!important;
        transform:none!important;
        transition:.5s;
        width:340px
    }
    #header-navigation .dropdown-menu.show{
        left:335px!important
    }
    #header-navigation .dropdown-menu .dropdown-menu.show{
        background:#fff;
        left:660px!important;
        width:340px
    }
    #header-navigation .dropdown-menu.dropdown-wide{
        width:580px
    }
    #header-navigation .dropdown-menu.dropdown-wide.show{
        left:335px!important
    }
    #header-navigation .dropdown-menu .dropdown-menu.show.dropdown-wide{
        left:670px!important
    }
    .footer .footer__content,.footer .footer__links,.footer div[class*=col-]>[slot]:not(:has(:is(.footer__links,.footer__content))){
        gap:1.5rem
    }
    .footer .primary-links{
        gap:1.5rem
    }
    .contact-us-form textarea{
        height:16em
    }
    .umb-grid .grid-section>*{
        padding:3rem 0
    }
}
@media only screen and (min-width:992px)and (min-width:1180px){
    #header-navigation .dropdown-menu .dropdown-menu.show{
        width:540px
    }
    #header-navigation .dropdown-menu.dropdown-wide{
        width:680px
    }
}
@media only screen and (min-width:992px)and (min-width:1280px){
    #header-navigation .dropdown-menu .dropdown-menu.show{
        width:540px
    }
    #header-navigation .dropdown-menu.dropdown-wide{
        width:800px
    }
}
@media only screen and (min-width:992px)and (min-width:1380px){
    #header-navigation .dropdown-menu .dropdown-menu.show{
        width:590px
    }
    #header-navigation .dropdown-menu.dropdown-wide{
        width:900px
    }
}
@media only screen and (min-width:992px)and (min-width:1480px){
    #header-navigation .dropdown-menu .dropdown-menu.show{
        width:640px
    }
}
@media only screen and (min-width:992px)and (min-width:1580px){
    #header-navigation .dropdown-menu .dropdown-menu.show{
        width:640px
    }
}
@media only screen and (min-width:992px)and (min-width:992px){
    #header-navigation .dropdown-menu>:first-child{
        margin-top:72px!important
    }
}
@media (min-width:1200px){
    #header-navigation .dropdown-item.dropdown-toggle.show,#header-navigation .dropdown-item.show,#header-navigation .nav-link.dropdown-toggle.show{
        background-color:#e9ecef
    }
    .news-article.v1.featured img{
        height:540px
    }
    .home .home-banner .banner__content{
        padding-top:305px
    }
    .umb-grid blockquote{
        padding:100px
    }
}
@media only screen and (min-width:1200px){
    .umb-grid .grid-section>*{
        padding:4rem 0
    }
}
@media (min-width:1500px){
    .tl-grid-cards__row{
        grid-template-columns:repeat(4,1fr)
    }
    .position-xxl-absolute{
        position:absolute
    }
}
@media (max-width:1199.98px){
    .navbar--header-main .navbar-nav{
        margin-bottom:1rem;
        margin-top:1rem
    }
    .navbar--header-main .nav-link{
        align-items:center;
        display:flex;
        padding-bottom:.5rem;
        padding-top:.5rem
    }
    .navbar--header-main .nav-link:after{
        margin-left:auto
    }
    .navbar--header-main .nav-link.show{
        color:#000
    }
    .navbar--header-main .nav-link.show:after{
        transform:rotate(180deg)
    }
    .navbar--header-main .dropdown-menu{
        border-bottom:none;
        border-left-width:3px;
        border-radius:0;
        border-right:none;
        border-top:none
    }
    .navbar--header-main .dropdown-item{
        font-size:.875rem;
        padding:.5rem 1.5rem
    }
}
@media (max-width:1199.98px)and (min-width:1200px){
    .navbar--header-main .nav-link.show{
        background-color:#e9ecef
    }
}
@media only screen and (max-width:992px){
    .dealer-nav-item{
        width:100%!important
    }
    .dealer-nav-item img{
        display:none!important
    }
    .dealer-nav-item .dropdown-item{
        background-color:transparent!important
    }
}
@media (max-width:991.98px){
    bsk-header .offcanvas-backdrop{
        display:none!important;
        pointer-events:none!important
    }
    #header-navigation{
        top:80px
    }
    #header-navigation .offcanvas-header{
        display:none!important
    }
    #header-navigation .offcanvas-body{
        background-color:#fff
    }
    #header-navigation{
        max-height:100vh;
        overflow:auto
    }
    #header-navigation .btn-close{
        height:1rem;
        left:auto;
        opacity:1;
        right:2rem!important;
        top:3rem!important;
        width:1rem
    }
    #header-navigation .navbar-nav{
        padding-bottom:4rem
    }
    #header-navigation .navbar-nav .dropdown-item.dropdown-toggle.show,#header-navigation .navbar-nav .nav-link.dropdown-toggle.show{
        border-color:#000
    }
    #header-navigation .navbar-nav .dropdown-item.dropdown-toggle.show:after,#header-navigation .navbar-nav .dropdown-item.dropdown-toggle:after,#header-navigation .navbar-nav .nav-link.dropdown-toggle.show:after,#header-navigation .navbar-nav .nav-link.dropdown-toggle:after{
        transform:rotate(90deg) translateY(-50%);
        transform-origin:top
    }
    #header-navigation .navbar-nav .dropdown-menu{
        padding:0!important
    }
    #header-navigation .navbar-nav .dropdown-menu .dropdown-item{
        padding-left:2rem
    }
    #header-navigation .navbar-nav .dropdown-menu .dropdown-item:active,#header-navigation .navbar-nav .dropdown-menu .dropdown-item:focus{
        background-color:none
    }
    #header-navigation .navbar-nav .dropdown-menu .dropdown-menu{
        padding:0!important
    }
    #header-navigation .navbar-nav .dropdown-menu .dropdown-menu .dropdown-item{
        padding-left:3rem
    }
    .spotlight .spotlight__headline{
        font-size:1.375rem
    }
    #testimonial-slider .slick-arrow{
        bottom:20px;
        top:unset
    }
    #hire-solution .carousel-control-prev{
        left:-5px
    }
    #hire-solution .carousel-control-next{
        right:-5px
    }
    .uvd #offers .carousel-control-next,.uvd #offers .carousel-control-prev{
        bottom:20px;
        top:unset
    }
}
@media (max-width:991.98px)and (min-width:1200px){
    #header-navigation .navbar-nav .dropdown-menu{
        background-color:#e9ecef
    }
    #header-navigation .navbar-nav .dropdown-menu .dropdown-menu{
        background-color:#fff
    }
}
@media only screen and (max-width:991px){
    .footer .container .row{
        gap:1.5rem
    }
    .footer .footer__content,.footer .footer__links,.footer div[class*=col-]>[slot]:not(:has(:is(.footer__links,.footer__content))){
        align-items:center;
        gap:1rem;
        justify-content:space-between
    }
    .footer .primary-links li{
        display:flex;
        justify-content:center;
        width:100%
    }
}
@media (max-width:767.98px){
    .thankyou .thankyou-content{
        margin-top:40px;
        padding:0 45px
    }
    .thankyou .thankyou-content h1{
        font-size:1.375rem
    }
    .thankyou .thankyou-content .btn{
        width:100%
    }
    .car-not-found .page-title{
        padding:0 85px
    }
    .car-not-found .page-title h1{
        font-size:1.375rem;
        line-height:27px
    }
    .car-not-found .page-title h3{
        font-size:1rem!important;
        font-weight:400;
        line-height:26px;
        margin-top:16px
    }
    .car-not-found #YouMightLike{
        margin-top:40px
    }
    .car-not-found #YouMightLike .slider-wrapper{
        background-color:#e9ecef;
        border-bottom:1px solid #dee2e6;
        border-top:1px solid #dee2e6;
        padding:46px 10px
    }
    .car-not-found #YouMightLike .slider-wrapper .slick-dots{
        margin-bottom:0
    }
    .car-not-found #YouMightLike .bg-continue-search{
        font-size:1.375rem;
        margin-top:82px!important
    }
}
@media (max-width:575.98px){
    header .navbar-logos{
        align-items:center;
        flex-direction:column;
        justify-content:center
    }
    header .navbar-logos a:first-child{
        padding:0 0 10px
    }
    header{
        background:#fff;
        color:#000
    }
    header .navbar-brand__img{
        filter:invert(1)
    }
    header a,header button{
        color:#000;
        outline:none;
        text-decoration:none
    }
    header .search-input{
        border-bottom:1px solid rgba(0,0,0,.5);
        color:#000
    }
    header .search-input::-moz-placeholder{
        color:#000
    }
    header .search-input::placeholder{
        color:#000
    }
    .franchise .navbar-toggler{
        margin-top:-2.2rem;
        padding:.25rem .3rem!important
    }
    .mon-logo img{
        height:auto!important;
        width:130px!important
    }
    .navbar-toggler{
        padding:.25rem .3rem!important
    }
    .navbar-brand,.navbar-brand__img{
        margin-left:1.8rem
    }
    .navbar-brand{
        margin-top:-1rem!important
    }
    .commercials-logo,.ford-logo,.seat-logo,.volkswagen-logo{
        margin-top:0!important
    }
}

bsk-accordion-item {
    display: block
}

bsk-article-full-width-cta .card {
    transition: border-color .2s ease-in-out
}

bsk-article-full-width-cta:hover .card {
    border-color: var(--bs-gray-500)
}

bsk-article-full-width-cta .card-body {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: .5rem
}

bsk-article-full-width-cta svg {
    grid-column: 1;
    grid-row: 1/span 2
}

bsk-article-full-width-cta .card-title {
    grid-column: 2
}

bsk-article-full-width-cta p {
    grid-column: 2;
    grid-row: 2
}

bsk-article-icon-stacked .card {
    background-color: transparent
}

bsk-article-icon-stacked .card-body {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: .5rem;
    align-items: center
}

bsk-article-icon-stacked svg {
    grid-column: 1;
    grid-row: 1/span 2
}

bsk-article-icon-stacked .card-title {
    grid-column: 2
}

bsk-article-icon-stacked p {
    grid-column: 2;
    grid-row: 2
}

bsk-article-icon .card {
    background-color: transparent
}

bsk-article-icon .card-body {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: .5rem;
    align-items: center;
    padding: .5rem
}

bsk-article-icon svg {
    grid-column: 1;
    grid-row: 1/span 2
}

bsk-article-icon .card-title {
    grid-column: 2
}

bsk-article-icon p {
    grid-column: 2;
    grid-row: 2
}

bsk-standard-carousel .slick-arrow {
    width: fit-content
}

bsk-standard-carousel .carousel-control-next {
    right: 1rem
}

bsk-standard-carousel .carousel-control-prev {
    left: 1rem
}

@media only screen and (min-width: 768px) {
    bsk-standard-carousel .carousel-control-next {
        right:-2.2rem
    }

    bsk-standard-carousel .carousel-control-prev {
        left: -2.2rem
    }
}

@media only screen and (min-width: 1192px) {
    bsk-standard-carousel .carousel-control-next {
        right:-3.125rem
    }

    bsk-standard-carousel .carousel-control-prev {
        left: -3.125rem
    }
}

@media only screen and (min-width: 1600px) {
    bsk-standard-carousel .carousel-control-next {
        right:-3.75rem
    }

    bsk-standard-carousel .carousel-control-prev {
        left: -3.75rem
    }
}

bsk-standard-carousel .slick-arrow path {
    fill: var(--bs-primary)
}

bsk-cta-section-full-width {
    --cta-section-section-fw-padding: 14vh
}

@media only screen and (min-width: 992px) {
    bsk-cta-section-full-width {
        --cta-section-section-fw-padding:18vh
    }
}

bsk-cta-section-full-width .cta-section-fw__img {
    aspect-ratio: var(--cta-section-fw-aspect-ratio);
    object-fit: cover
}

bsk-cta-section-full-width .input-group {
    gap: .5rem
}

@media only screen and (max-width: 575px) {
    bsk-cta-section-full-width[is=service-booking] input.form-control {
        width:100%
    }
}

@media only screen and (max-width: 767px) {
    bsk-cta-section-full-width {
        text-align:center
    }
}

@media only screen and (min-width: 992px) {
    bsk-cta-section-full-width .input-group .form-control--cta-section-fw {
        flex:0 0 auto;
        width: 58.33333333%
    }

    bsk-cta-section-full-width .input-group.part-ex {
        max-width: 550px
    }

    bsk-cta-section-full-width :is(.input-group.sb,p:has(+.sb)) {
        max-width: 820px
    }
}

bsk-cta-section .cta-section__img {
    object-fit: cover
}

bsk-cta-section .input-group {
    gap: .5rem
}

@media only screen and (max-width: 767px) {
    bsk-cta-section {
        text-align:center
    }
}

@media only screen and (min-width: 992px) {
    bsk-cta-section .cta-section__img {
        aspect-ratio:var(--cta-section-aspect-ratio)
    }

    bsk-cta-section .input-group .form-control--cta-section {
        flex: 0 0 auto;
        width: 58.33333333%
    }
}

bsk-dealer-finder {
    --offset-top: 15vh;
    --offset-top-mob: 7vh
}

bsk-dealer-finder .offcanvas {
    z-index: -1;
    height: calc(100vh - var(--offset-top))
}

bsk-dealer-finder .offcanvas-top {
    top: var(--offset-top)
}

@media only screen and (max-width: 991px) {
    bsk-dealer-finder .offcanvas {
        height:calc(100vh - var(--offset-top-mob))
    }

    bsk-dealer-finder .offcanvas-top {
        top: var(--offset-top-mob)
    }
}

bsk-dealer-finder .offcanvas-backdrop {
    display: none!important
}

bsk-dealer-finder .nav-pills .nav-link.active {
    background-color: initial;
    border: 1px solid var(--bs-primary)
}

bsk-dealership-card-group {
    --arrows-offset: -7.5vw;
    --arrows-height: calc(100% - 70px)
}

@media only screen and (min-width: 1200px) {
    bsk-dealership-card-group {
        --arrows-offset:-8vw
    }
}

bsk-dealership-card-group .carousel-control-prev {
    left: var(--arrows-offset);
    height: var(--arrows-height)
}

bsk-dealership-card-group .carousel-control-next {
    right: var(--arrows-offset);
    height: var(--arrows-height)
}

[slot=distance] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: var(--bs-dark);
    color: var(--bs-white);
    padding: var(--bsk-spacer-3) var(--bsk-spacer-2);
    margin-bottom: var(--bsk-spacer-3);
    display: block;
    text-align: center;
    border-radius: var(--bsk-border-radius)
}

bsk-locations:not([is=map],[is=tabs]) .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem
}

bsk-locations[is=map] .hours-tabs__outer {
    grid-template-columns: 1fr;
    gap: 2rem
}

bsk-locations[is=map] .hours-tabs__outer>* {
    width: 100%
}

bsk-locations[is=map] .hours-tabs__inner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5rem auto
}

bsk-locations[is=map] [slot=iframe] {
    width: 100%
}

bsk-locations[is=map] iframe {
    height: 100%
}

bsk-locations:is([is=tabs],[is=map]) .bg-img {
    background-image: url("https://jer240423.dev.cogplatform.co.uk/img/opening-hours/locations-bg.webp")
}

bsk-locations[is=tabs] .hours-tabs__outer {
    grid-template-columns: 1fr;
    gap: 2rem
}

bsk-locations[is=tabs] .hours-tabs__outer>* {
    width: 100%
}

bsk-locations[is=tabs] .hours-tabs__inner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto
}

bsk-locations[is=tabs] [slot^=card] .d-grid {
    padding: var(--bsk-spacer-4)
}

bsk-locations[is=tabs] [slot=iframe] {
    width: 100%
}

bsk-locations[is=tabs] iframe {
    max-height: 24rem
}

bsk-locations[is=tabs] .table td {
    padding: 1rem .5rem
}

@media only screen and (max-width: 575px) {
    bsk-locations[is=tabs] .hours-tabs__inner {
        grid-template-columns:1fr;
        gap: 2rem
    }

    bsk-locations[is=tabs] .hours-tabs__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (max-width: 575px) {
    bsk-locations[is=map] .hours-tabs__inner {
        grid-template-columns:1fr;
        gap: 2rem
    }

    bsk-locations[is=map] .hours-tabs__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-locations[is=map] iframe {
        max-height:100%
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-locations[is=tabs] iframe {
        max-height:100%
    }
}

@media only screen and (min-width: 992px) {
    bsk-locations[is=tabs] .hours-tabs__outer {
        grid-template-columns:7fr 5fr
    }

    bsk-locations[is=tabs] .hours-tabs__iframe {
        align-items: flex-end
    }
}

@media only screen and (min-width: 992px) {
    bsk-locations[is=map] .hours-tabs__outer {
        grid-template-columns:1fr 1fr
    }

    bsk-locations[is=map] .hours-tabs__iframe {
        align-items: flex-end
    }
}

@media only screen and (min-width: 1200px) {
    bsk-locations:not([is=map],[is=tabs]) .card-grid {
        grid-template-columns:1fr 1fr
    }
}

@media only screen and (min-width: 1600px) {
    bsk-locations[is=tabs] iframe {
        max-height:26rem
    }
}

bsk-locations[is=simple] .hours-simple__inner {
    grid-template-columns: 1fr;
    grid-template-rows: 5rem auto
}

@media only screen and (max-width: 575px) {
    bsk-locations[is=simple] .hours-simple__inner {
        grid-template-columns:1fr;
        gap: 0
    }

    bsk-locations[is=simple] .hours-simple__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (min-width: 992px) {
    .hours-simple__cards {
        grid-column:1/3
    }
}

bsk-locations[is=simple] .slick-slider .slick-slide {
    margin: 0
}

bsk-locations[is=simple] .slick-slider .slick-dots {
    position: relative;
    margin: 0
}

bsk-locations[is=tabs] .nav-tabs {
    border-bottom: none;
    gap: 1rem
}

bsk-locations[is=tabs] .nav-tabs .nav-link {
    background-color: transparent;
    border-color: transparent;
    padding: 0
}

bsk-locations[is=tabs] .nav-tabs .nav-link.active {
    position: relative
}

bsk-locations[is=tabs] .nav-tabs .nav-link::after {
    position: absolute;
    bottom: -.5rem;
    left: 0;
    content: "";
    display: block;
    width: 0%;
    height: 3px;
    background-color: var(--bs-secondary);
    margin-top: .5rem;
    transition: width .3s
}

bsk-locations[is=tabs] .nav-tabs .nav-link.active::after {
    width: 100%
}

bsk-locations[is=tabs] hr {
    transform: translateY(6.5px);
    height: 2px;
    background-color: var(--bs-white);
    margin-top: 0
}

@media only screen and (max-width: 991px) {
    bsk-locations[is=tabs] .tab-content {
        padding:1rem
    }

    bsk-locations[is=tabs] [slot^=card] .d-grid {
        padding: 1rem 0
    }

    bsk-locations[is=map] iframe {
        max-height: initial!important;
        height: 400px!important
    }
}

bsk-locations[is=map] address {
    color: #fff!important;
    line-height: 2
}

bsk-repo-home-card .card {
    transition: 350ms
}

bsk-footer-content[is=centered-links] ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: .75rem;
    margin-top: 0;
    margin-bottom: 0
}

@media only screen and (min-width: 992px) {
    bsk-footer-content[is=centered-links] ul {
        gap:1.5rem
    }
}

@media only screen and (max-width: 991px) {
    bsk-footer-content[is=centered-links] li {
        display:flex;
        justify-content: center;
        width: 100%
    }
}

bsk-footer-lg-disclosure-group :is(.accordion-item,.accordion-header,.accordion-button,.accordion-button:focus) {
    background-color: var(--bs-dark);
    color: var(--bs-white)
}

bsk-footer-lg-disclosure-group .accordion-item {
    border: none
}

bsk-footer-lg-disclosure-group .accordion-button:focus {
    box-shadow: none
}

bsk-footer-lg-disclosure-group .accordion-item .accordion-button::after {
    content: '';
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size: 1.2rem;
    mask-size: 1.2rem;
    background-color: var(--bs-white);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 150ms;
    transform: rotate(0)
}

bsk-footer-lg-disclosure-group .accordion-item[open] .accordion-button::after {
    transform: rotate(180deg)
}

bsk-footer-lg-disclosure-group .list-group-item {
    padding: .5rem 0;
    border-bottom: none
}

@media only screen and (max-width: 991px) {
    bsk-footer-lg-disclosure-group .accordion-item {
        border-bottom:1px solid var(--bs-gray-200)
    }
}

@media only screen and (min-width: 992px) {
    bsk-footer-lg-disclosure-group .accordion {
        height:100%
    }

    bsk-footer-lg-disclosure-group .accordion-button {
        border-bottom: 1px solid var(--bs-gray-200);
        pointer-events: none
    }

    bsk-footer-lg-disclosure-group .accordion-item .accordion-button::after {
        display: none
    }

    bsk-footer-lg-disclosure-group[is=form-footer] .accordion-item:nth-of-type(odd) .list-group {
        display: grid;
        grid-template-columns: 1fr 1fr
    }
}

bsk-footer-lg article P {
    max-width: 64ch
}

bsk-footer-lg .w-100:has([slot=footer-right]) {
    overflow-x: hidden
}

bsk-footer-lg [slot=footer-right] {
    display: block;
    position: relative;
    z-index: 2;
    padding: 0 2rem
}

bsk-footer-lg div[class*=col]:has([slot=footer-right]):after {
    position: absolute;
    top: -50vh;
    left: 0;
    width: 100vw;
    height: 200vh;
    content: '';
    background-color: var(--bs-light);
    z-index: 0
}

@media only screen and (max-width: 991px) {
    bsk-footer-lg div[class*=col]:has([slot=footer-right]) {
        overflow:hidden
    }
}

bsk-gallery-actions [slot=actions] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.75rem;
    width: 100%;
    gap: 1.75rem
}

bsk-gallery-actions:not([is=buttons]) [slot=actions] {
    justify-content: flex-start;
    margin-top: 0
}

bsk-gallery {
    --arrow-distance: 0%;
    padding: 0!important;
    position: relative
}

bsk-gallery .slick-slider .slick-slide {
    margin: 0
}

.gallery .carousel-control-prev {
    left: var(--arrow-distance)
}

.gallery .carousel-control-next {
    right: var(--arrow-distance)
}

.gallery :is(.carousel-control-prev,.carousel-control-next) svg {
    padding: 1rem;
    height: 30px;
    width: 30px
}

.gallery svg:not(.svg-inline--fa) path {
    fill: var(--bs-dark)
}

.gallery__img-count {
    z-index: 2;
    left: 1.5rem;
    bottom: 1rem
}

.gallery-thumbs {
    cursor: pointer;
    padding: 0!important
}

.gallery-thumbs .slick-slider .slick-slide {
    margin: 0
}

.gallery-thumbs .slick-slide>div:first-of-type {
    margin-bottom: -6px
}

bsk-gallery>div {
    height: 100%;
    display: flex;
    align-items: center
}

bsk-gallery [slot=gallery-actions] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin: .25rem 0 0 .5rem
}

.gallery iframe {
    width: 100%;
    height: var(--gallery-video-height)
}

bsk-grid-masonry [slot=grid]:not(.slick-initialized) {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    gap: 1rem
}

bsk-grid-masonry [slot=grid]>* {
    grid-column: 1/span 12
}

bsk-grid-masonry .slick-slider .slick-dots {
    margin: .5rem auto .5rem
}

@media only screen and (min-width: 576px) and (max-width:991px) {
    bsk-grid-masonry:not([is=alt-one]) [slot=grid]:not(.slick-initialized)>:is(:first-child,:nth-child(2)) {
        grid-column:span 6
    }

    bsk-grid-masonry:not([is=alt-one]) [slot=grid]:not(.slick-initialized)>:not(:first-child,:nth-child(2)) {
        grid-column: span 4
    }
}

@media only screen and (min-width: 992px) {
    bsk-grid-masonry:not([is=alt-one]) [slot=grid]>:is(:first-child,:nth-child(2)) {
        grid-column:span 6
    }

    bsk-grid-masonry:not([is=alt-one]) [slot=grid]>:not(:first-child,:nth-child(2)) {
        grid-column: span 4
    }
}

@media only screen and (min-width: 1200px) {
    bsk-grid-masonry:not([is=alt-one])>[slot=grid] {
        gap:1.5rem
    }
}

@media only screen and (min-width: 768px) {
    bsk-grid-masonry[is=alt-one] img {
        object-fit:cover
    }

    bsk-grid-masonry[is=alt-one] [slot=grid] {
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(2,1fr);
        gap: 1.5rem
    }

    bsk-grid-masonry[is=alt-one] [slot=grid]>a {
        grid-column: span 1
    }

    bsk-grid-masonry[is=alt-one] [slot=grid]>:first-child {
        grid-row: span 2
    }

    bsk-grid-masonry[is=alt-one] [slot=grid]>:nth-child(2) {
        grid-column: span 2
    }
}

html {
    --component-gap: 3rem
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    html {
        --component-gap:4rem
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    html {
        --component-gap:5rem
    }
}

@media only screen and (min-width: 992px) {
    html {
        --component-gap:6rem
    }
}

@media only screen and (min-width: 1200px) {
    html {
        --component-gap:7.5rem
    }
}

bsk-grid-section-spacer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--component-gap)
}

bsk-grid-section-spacer>* {
    width: 100%
}

bsk-grid-section-spacer>.exclude-from-gap {
    margin-top: calc(0rem - var(--component-gap))
}

bsk-grid-section-spacer>:last-child {
    margin-bottom: var(--component-gap)
}

bsk-grid-slick-to-row [slot=grid]:not(.slick-initialized) {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    gap: 1rem
}

bsk-grid-slick-to-row [slot=grid]>* {
    grid-column: 1/span 12
}

bsk-grid-slick-to-row .slick-slider .slick-dots {
    margin: .5rem auto 1rem
}

bsk-grid-slick-to-row:has(bsk-thumbnail-logo-links) .slick-slider .slick-dots {
    margin: -.5rem auto 1rem
}

@media only screen and (min-width: 992px) {
    bsk-grid-slick-to-row [slot=grid]>* {
        grid-column-start:auto;
        grid-column-end: span 3
    }

    bsk-grid-slick-to-row[is=large] [slot=grid]>* {
        grid-column-end: span 6
    }
}

bsk-header:is([is*=centre]) [slot=nav] {
    display: flex;
    align-items: center;
    justify-content: center
}

bsk-header:not([is*=centre]) [class*=container] {
    justify-content: flex-start
}

bsk-header:not([is*=centre]) .navbar-toggler {
    margin-left: auto
}

@media only screen and (max-width: 767px) {
    bsk-header:is([is=centre-with-burger]) [slot=nav] {
        display:none
    }

    bsk-header:is([is*=centre]) [slot=nav] {
        flex-direction: column
    }

    .header-logo {
        height: 40px;
        width: auto
    }
}

@media only screen and (min-width: 768px) {
    bsk-header:is([is*=centre]) .navbar-collapse {
        flex-grow:0
    }

    bsk-header:is([is*=centre]) .navbar-collapse {
        display: flex;
        flex-basis: initial
    }

    bsk-header:is([is=centre-with-burger]) div[class*=container]>* {
        width: 33%;
        display: flex;
    }
}

bsk-carousel-banner-slide .title {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

bsk-carousel-banner-slide .yt-wrapper {
    --yt-aspect-ratio: 16/9;
    aspect-ratio: var(--yt-aspect-ratio,16/9);
    transform: scale(2)
}

@media only screen and (max-width: 767px) {
    bsk-carousel-banner-slide .yt-wrapper {
        --yt-aspect-ratio:480/360
    }
}

@media only screen and (min-width: 768px) and (max-width:1199px) {
    bsk-carousel-banner-slide .yt-wrapper {
        --yt-aspect-ratio:1440/660
    }
}

@media only screen and (min-width: 1200px) {
    bsk-carousel-banner-slide .yt-wrapper {
        --yt-aspect-ratio:1875/780
    }
}

bsk-carousel-banner-slide .hero-img {
    z-index: 2;
    transition: opacity 1s ease
}

bsk-carousel-banner-slide .hero-img.fade-out {
    opacity: 0
}

bsk-carousel-banner[dots=true] .slick-dots {
    display: flex!important;
    position: absolute;
    bottom: 1rem
}

bsk-carousel-banner:not([dots=true]) .slick-dots {
    display: none!important
}

@media only screen and (max-width: 767px) {
    bsk-carousel-banner[dots=true] .slick-dots {
        bottom:.25rem;
        margin: 0;
        left: 0;
        justify-content: flex-end;
        padding-right: .5rem
    }
}

.banner-slider .slick-slide {
    margin: 0 0
}

bsk-video-banner {
    --hero-bg-height: 100vh;
    --hero-bg-height-mobile: calc(100vh - 93px);
    --video-width: 100vw;
    --video-height: 100vh
}

@media (min-aspect-ratio: 16/9) {
    bsk-video-banner {
        --video-height:56.25vw
    }
}

@media (max-aspect-ratio: 16/9) {
    bsk-video-banner {
        --video-width:177.78vh
    }
}

.video-banner {
    height: var(--hero-bg-height)
}

@media only screen and (max-width: 575px) {
    .video-banner {
        height:var(--hero-bg-height-mobile)
    }
}

.video-banner iframe {
    width: var(--video-width);
    height: var(--video-height);
    transform: translate(-50%,-50%);
    opacity: .8
}

@media only screen and (max-width: 575px) {
    .video-banner__content {
        text-align:center;
        padding: 1rem
    }
}

.video-banner__headline {
    font-size: 3.5rem;
    letter-spacing: 3px
}

@media only screen and (max-width: 575px) {
    .video-banner__headline {
        font-size:2rem;
        letter-spacing: normal;
        margin: 0
    }
}

@media only screen and (max-width: 575px) {
    .video-banner__synopsis {
        font-size:2rem!important;
        line-height: 1.2
    }
}

bsk-location-contact a {
    color: var(--bsk-dark);
    text-decoration: none
}

bsk-location-hours tr td {
    padding: 10px 0;
    border-bottom: 1px solid #c3c3c3
}

bsk-locations[is=tabs] .table td:nth-of-type(2) {
    text-align: end
}

bsk-location-hours[is=simple] tr td:first-of-type {
    padding-right: 4rem
}

bsk-location-hours table {
    width: 100%
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    bsk-location-hours[is=simple] tr td:first-of-type {
        padding-right:2rem
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-location-hours[is=simple] tr td:first-of-type {
        padding-right:1rem
    }
}

@media only screen and (min-width: 1200px) {
    bsk-location-hours[is=simple] tr td:first-of-type {
        padding-right:2rem
    }
}

@media only screen and (min-width: 1600px) {
    bsk-location-hours[is=simple] tr td:first-of-type {
        padding-right:4rem
    }
}

bsk-location-hours tr td:first-of-type {
    padding-right: 4rem
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    bsk-location-hours tr td:first-of-type {
        padding-right:2rem
    }
}

.breadcrumb-item a {
    transition: .3s;
    color: var(--bs-secondary)
}

.breadcrumb-item a:hover {
    color: var(--bs-dark)
}

read-more {
    cursor: pointer
}

read-more .showing svg {
    transform: rotate(180deg)
}

bsk-tabs .accordion-button:after {
    content: "";
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-size: 1rem;
    mask-size: 1rem;
    background-color: var(--bs-dark);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 150ms
}

bsk-tabs .tab-pane.card {
    border: none
}

bsk-tabs .accordion-button:focus {
    box-shadow: none
}

bsk-tabs .card-header .accordion-button[aria-expanded=true]:after {
    transform: rotate(180deg)
}

@media only screen and (max-width: 767px) {
    bsk-tabs[is=accordions] div:has(>[slot]) {
        padding:var(--bsk-spacer-3)
    }

    bsk-tabs .tab-content>.tab-pane {
        display: initial
    }

    bsk-tabs .fade:not(.show) {
        opacity: initial
    }
}

bsk-cap-card-body>div {
    display: flex;
    flex-direction: column
}

bsk-cap-card-body>* {
    width: 100%
}

bsk-cap-card-img picture:not(:first-of-type) {
    display: none
}

bsk-inline-offer-card .card-text p {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-inline-offer-card img {
        aspect-ratio:1/1
    }
}

bsk-offer-card img {
    aspect-ratio: 55/33;
    object-fit: cover
}

bsk-offer-card {
    height: 100%;
    display: block
}

bsk-opening-hours-card {
    --bsk-grid-cols: 1fr 1fr
}

bsk-opening-hours-card[is=simple] {
    --bsk-grid-cols: 1fr 3rem
}

bsk-opening-hours-card>section {
    grid-template-columns: var(--bsk-grid-cols)
}

bsk-opening-hours-card .mhMobile>a {
    color: #fff
}

@media only screen and (max-width: 575px) {
    bsk-opening-hours-card>section {
        grid-template-columns:1fr;
        gap: 2rem
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-opening-hours-card[is=tabs]>section {
        grid-template-columns:1fr;
        gap: 2rem
    }
}

bsk-opening-hours:not([is=map],[is=tabs]) .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem
}

@media only screen and (min-width: 1200px) {
    bsk-opening-hours:not([is=map],[is=tabs]) .card-grid {
        grid-template-columns:1fr 1fr
    }
}

bsk-opening-hours[is=map] .hours-tabs__outer {
    grid-template-columns: 1fr;
    gap: 2rem
}

bsk-opening-hours[is=map] .hours-tabs__outer>* {
    width: 100%
}

bsk-opening-hours[is=map] .hours-tabs__inner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5rem auto
}

bsk-opening-hours[is=map] [slot=iframe] {
    width: 100%
}

bsk-opening-hours[is=map] iframe {
    max-height: 18rem
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-opening-hours[is=map] iframe {
        max-height:100%
    }
}

@media only screen and (max-width: 575px) {
    bsk-opening-hours[is=map] .hours-tabs__inner {
        grid-template-columns:1fr;
        gap: 2rem
    }

    bsk-opening-hours[is=map] .hours-tabs__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (min-width: 992px) {
    bsk-opening-hours[is=map] .hours-tabs__outer {
        grid-template-columns:1fr 1fr
    }

    bsk-opening-hours[is=map] .hours-tabs__iframe {
        align-items: flex-end
    }
}

@media only screen and (min-width: 1600px) {
    bsk-opening-hours iframe {
        max-height:18rem
    }
}

bsk-opening-hours[is=tabs] .hours-tabs__outer {
    grid-template-columns: 1fr;
    gap: 2rem
}

bsk-opening-hours[is=tabs] .hours-tabs__outer>* {
    width: 100%
}

bsk-opening-hours[is=tabs] .hours-tabs__inner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5rem auto
}

bsk-opening-hours[is=tabs] [slot^=card] .d-grid {
    padding: var(--bsk-spacer-4)
}

bsk-opening-hours[is=tabs] [slot=iframe] {
    width: 100%
}

bsk-opening-hours[is=tabs] iframe {
    max-height: 24rem
}

@media only screen and (max-width: 575px) {
    bsk-opening-hours[is=tabs] .hours-tabs__inner {
        grid-template-columns:1fr;
        gap: 2rem
    }

    bsk-opening-hours[is=tabs] .hours-tabs__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (min-width: 992px) {
    bsk-opening-hours[is=tabs] .hours-tabs__outer {
        grid-template-columns:1fr 1fr
    }

    bsk-opening-hours[is=tabs] .hours-tabs__iframe {
        align-items: flex-end
    }
}

@media only screen and (min-width: 1600px) {
    bsk-opening-hours[is=tabs] iframe {
        max-height:18rem
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    bsk-opening-hours[is=tabs] iframe {
        max-height:100%
    }
}

bsk-opening-hours[is=simple] .hours-simple__inner {
    grid-template-columns: 1fr;
    grid-template-rows: 5rem auto
}

@media only screen and (max-width: 575px) {
    bsk-opening-hours[is=simple] .hours-simple__inner {
        grid-template-columns:1fr;
        gap: 0
    }

    bsk-opening-hours[is=simple] .hours-simple__inner>div:nth-of-type(2) {
        grid-column: 1/3
    }
}

@media only screen and (min-width: 992px) {
    .hours-simple__cards {
        grid-column:1/3
    }
}

bsk-opening-hours[is=simple] .slick-slider .slick-slide {
    margin: 0
}

bsk-opening-hours[is=simple] .slick-slider .slick-dots {
    position: relative;
    margin: 0
}

bsk-page-hero-img[is=lg] .main-image {
    height: calc(var(--hero-height)/ 2)
}

@media only screen and (min-width: 768px) {
    bsk-page-hero-img[is=lg] .main-image {
        height:var(--hero-height)
    }
}

bsk-page-hero-img[is=lg] .main-image img {
    -o-object-fit: cover;
    object-fit: cover;
    opacity: var(--hero-img-opacity, .6);
    transform: scale(2);
    animation: zoomOutIn .6s cubic-bezier(.54,.46,.54,.94) 0s forwards
}

bsk-page-hero-img[is=lg] .main-image__text {
    text-shadow: 0 0 .625rem rgba(0,0,0,.5);
    z-index: 2;
    transform: translateY(150%)
}

bsk-page-hero-img[is=lg] .loaded .main-image__text {
    animation: slideIn .4s cubic-bezier(.54,.46,.54,.94) .4s forwards
}

@keyframes zoomOutIn {
    to {
        transform: scale(1)
    }
}

@keyframes slideIn {
    to {
        transform: translateY(0)
    }
}

bsk-page-hero-img:not([is=lg]) .image-bg {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

bsk-page-hero-img[is=fixed] .image-bg {
    background-attachment: fixed
}

bsk-page-hero-img:not([is=lg]) .image-bg.loaded {
    background-image: var(--hero-image)
}

bsk-page-hero-img:not([is=lg]) .image-bg:before {
    background: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0
}

bsk-page-hero-img:not([is=lg]) .image-bg>* {
    position: relative;
    z-index: 1
}

@media only screen and (max-width: 991px) {
    .page-hero__img {
        max-width:40vw
    }
}

@media only screen and (min-width: 992px) {
    .page-hero__img {
        transform:translateX(calc(var(--bs-gutter-x) * .5))
    }

    .page-hero-has-img {
        background: linear-gradient(90deg,#333 55%,transparent 80%)!important
    }

    .img-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0
    }
}

.img-wrapper {
    z-index: -1
}

.page-hero-has-img img {
    object-fit: cover;
    height: 100%;
    width: 100%
}

bsk-review .testimonial img {
    width: 7rem;
    height: 7rem;
    border-radius: 50rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center
}

@media only screen and (max-width: 991px) {
    bsk-review .testimonial__body {
        max-width:560px
    }
}

@media only screen and (min-width: 992px) {
    bsk-review .position-xl-absolute {
        position:absolute!important
    }
}

.global-heart:after {
    content: attr(data-count)
}

bsk-shortlist-local-heart {
    position: absolute;
    top: 0;
    right: 0;
    margin: .5rem 1rem 0 0;
    z-index: 2
}

.local-heart[data-select-msg]:after {
    font-size: 1rem
}

bsk-simple-spotlight-group[is=strip] .card {
    background-color: var(--bs-light)!important
}

bsk-thumbnail-logo-links .d-grid {
    grid-template-columns: repeat(var(--logo-cols),1fr)
}

.bsk-thumbnail__synopsis {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden
}

bsk-thumbnail:not([is=hover]) .bsk-thumbnail__img {
    object-fit: cover
}

bsk-thumbnail[is=hover] .bsk-thumbnail__img {
    opacity: .5;
    transition: .5s
}

bsk-thumbnail[is=hover] .bsk-thumbnail:hover .bsk-thumbnail__img {
    filter: blur(5px)
}

bsk-thumbnail[is=hover] .bsk-thumbnail__content {
    pointer-events: none;
    transition: .5s
}

bsk-thumbnail[is=hover] .bsk-thumbnail:hover .bsk-thumbnail__content {
    bottom: 0;
    pointer-events: all
}

bsk-thumbnail[is=hover] .bsk-thumbnail__synopsis {
    max-height: 0;
    transition: 1s
}

bsk-thumbnail[is=hover] .bsk-thumbnail:hover .bsk-thumbnail__synopsis {
    max-height: 180px;
    overflow: hidden
}

@media only screen and (max-width: 575px) {
    bsk-thumbnail[is=hover] .bsk-thumbnail__content {
        height:180px
    }
}

@media only screen and (min-width: 576px) and (max-width:767.98px) {
    bsk-thumbnail[is=hover] .bsk-thumbnail__content {
        height:180px
    }
}

@media (max-width: 767.98px) {
    bsk-thumbnail[is=hover] .bsk-thumbnail__content {
        bottom:0;
        height: 180px;
        max-height: 180px!important;
        pointer-events: all
    }

    bsk-thumbnail[is=hover] .bsk-thumbnail__synopsis {
        bottom: 2rem;
        left: 1rem;
        max-height: 180px!important;
        pointer-events: all;
        position: absolute;
        overflow: hidden
    }
}

bsk-ucr-grid>.d-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem
}

@media only screen and (min-width: 768px) {
    bsk-ucr-grid>.d-grid {
        grid-template-columns:repeat(2,1fr)
    }
}

@media only screen and (min-width: 1600px) {
    bsk-ucr-grid>.d-grid {
        grid-template-columns:repeat(3,1fr)
    }
}

bsk-ucr-grid>.d-grid.list-view {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem
}

bsk-ucr-grid .list-transition.list-view>* {
    animation-duration: .4s;
    transform: translateX(-200vw);
    animation-delay: calc(var(--grid-item-index) * 100ms);
    animation-fill-mode: forwards;
    animation-name: slidein
}

bsk-ucr-grid .grid-transition:not(.list-view)>* {
    animation-delay: none;
    animation-delay: calc(var(--grid-item-index) * 50ms);
    animation-duration: 80ms;
    animation-name: slideout
}

@keyframes slidein {
    from {
        transform: translateX(-200vw)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes slideout {
    from {
        transform: scale(.8)
    }

    to {
        transform: scale(1)
    }
}

bsk-ucr-list-grid-toggle {
    display: block
}

bsk-ucr-list-grid-toggle :is(span,path) {
    transition: 250ms
}

bsk-ucr-list-grid-toggle .active {
    border-color: var(--bs-gray-500)!important
}

bsk-ucr-list-grid-toggle .active path {
    fill: var(--bs-dark)
}

bsk-vehicle-card-actions .btn-primary {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary)
}

bsk-vehicle-card-actions[is=blank] .btn {
    width: fit-content;
    margin: auto;
    position: relative
}

bsk-vehicle-card-actions[is=blank] .btn:after {
    content: "";
    position: absolute;
    width: calc(100% - 2.5rem);
    height: 2px;
    background-color: var(--bs-primary);
    bottom: -1px
}

bsk-vehicle-card-body>div {
    display: flex;
    flex-direction: column
}

bsk-vehicle-card-body>* {
    width: 100%
}

bsk-vehicle-card-icon svg[style*="--circle-size"] {
    width: var(--circle-size);
    height: var(--circle-size)
}

bsk-vehicle-card-img-count {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0
}

bsk-vehicle-card-img {
    position: relative
}

.vehicle-card-img__img.list-view {
    aspect-ratio: 1.4/1;
    object-fit: cover
}

@media only screen and (min-width: 992px) {
    .vehicle-card-img__img.list-view {
        aspect-ratio:1.6/1
    }
}

bsk-vehicle-card-location a {
    font-weight: 600
}

bsk-vehicle-card-pricing {
    width: 100%
}

bsk-vehicle-card-pricing[is=bordered] {
    border-top: 1px solid var(--bs-gray-300);
    border-bottom: 1px solid var(--bs-gray-300);
    padding-bottom: var(--bsk-spacer-3)
}

bsk-vehicle-card-pricing p.list-view {
    width: fit-content
}

bsk-vehicle-card-pricing[is=was-now-save] span {
    grid-column: var(--grid-col);
    grid-row: var(--grid-row)
}

bsk-vehicle-card-pricing[is=was-now-save] .save {
    font-weight: 600;
    color: var(--bs-primary)
}

.sash {
    left: 0;
    position: absolute;
    top: 0;
    height: 7rem;
    width: 7rem;
    z-index: 2;
    overflow: hidden
}

.sash__ribbon {
    left: .2rem;
    position: absolute;
    top: 2rem;
    height: 1.5rem;
    width: 8.656rem;
    transform: translate(-38px,-8px) rotate(-45deg);
    text-align: center;
    background-color: var(--bs-primary)
}

@media only screen and (min-width: 576px) {
    .sash__ribbon {
        top:2.6rem;
        width: 10.456rem
    }
}

.sash__ribbon::after {
    content: attr(data-sash-text);
    color: var(--bs-dark);
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1rem
}

bsk-vehicle-card-specs[is=bordered] {
    border-top: 1px solid var(--bs-gray-300);
    border-bottom: 1px solid var(--bs-gray-300);
    padding-bottom: var(--bsk-spacer-3)
}

bsk-vehicle-card-specs[icons] .vehicle-card-specs__copy.d-grid {
    grid-template-columns: 1fr 1fr
}

.vehicle-card-specs__copy.list-view {
    width: fit-content
}

.vehicle-card-specs__copy .spec-text {
    font-size: var(--specs-font-size, .75rem)
}

@media only screen and (max-width: 991px) {
    .vehicle-card-specs__copy.list-view {
        display:none!important
    }
}

@container (max-width:238px) {
    bsk-vehicle-card-specs[icons] .vehicle-card-specs__copy.d-grid {
        grid-template-columns: 1fr
    }
}

bsk-vehicle-card-titles>* {
    width: 100%
}

.vehicle-card-titles__title.list-view {
    font-size: 1.5rem
}

.vehicle-card-titles__sub-title.list-view {
    font-size: 1.5rem
}

@media only screen and (min-width: 768px) {
    .bsk-vehicle-card.list-view {
        display:grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: auto;
        grid-gap: 1.5rem
    }

    .bsk-vehicle-card.list-view bsk-vehicle-card-img {
        grid-column: 1/span 2
    }

    .bsk-vehicle-card.list-view bsk-vehicle-card-body {
        grid-column: 3/span 2;
        width: 100%;
        margin: auto
    }
}

@media only screen and (min-width: 1400px) {
    .bsk-vehicle-card.list-view {
        grid-template-columns:repeat(5,1fr)
    }

    .bsk-vehicle-card.list-view bsk-vehicle-card-body {
        grid-column: 3/span 3
    }
}

bsk-anchor-group .sticky {
    position: relative;
    transition: top .5s;
    z-index: 999;
    opacity: .95
}

bsk-anchor-group .sticky.fixed {
    position: fixed;
    top: 0
}

bsk-anchor-group .nav-link {
    color: var(--bs-dark)
}

bsk-anchor-group .nav-link.active {
    color: var(--bs-primary)!important
}

bsk-cap-data-point-group {
    display: block;
    width: 100%
}

@media only screen and (max-width: 767px) {
    bsk-cap-data-point-group>div {
        max-width:400px;
        margin: auto
    }
}

@media only screen and (min-width: 992px) {
    bsk-cap-data-point-group {
        padding:0 0 5rem 0
    }
}

@media only screen and (min-width: 1600px) {
    bsk-cap-data-point-group>div {
        max-width:75vw;
        margin: auto
    }

    bsk-cap-data-point-group [slot=cards] {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: var(--bsk-spacer-3);
        width: 100%
    }
}

bsk-cap-data-point-group .slick-slider .slick-slide {
    margin: 0
}

bsk-cap-data-point[is=card] .card {
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 9rem
}

@media only screen and (min-width: 1200px) {
    bsk-cap-data-point[is=card] .card {
        width:9rem;
        height: 8rem
    }
}

bsk-cap-data-point[is=grid] .card {
    background-color: transparent
}

bsk-cap-data-point[is=grid] .card-body {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: .25rem;
    align-items: center
}

bsk-cap-data-point[is=grid] span:has(svg,img) {
    grid-column: 1;
    grid-row: 1/span 2
}

bsk-cap-data-point[is=grid] .card-title {
    grid-column: 2
}

bsk-cap-data-point[is=grid] p {
    grid-column: 2;
    grid-row: 2
}

bsk-cap-data-point img {
    object-fit: contain
}

bsk-nav-group .sticky {
    position: relative;
    transition: top .5s;
    z-index: 999;
    opacity: .95
}

bsk-nav-group .sticky.fixed {
    position: fixed;
    top: 0
}

bsk-nav-group a.active {
    color: var(--bs-primary)!important
}

bsk-specs-table tr {
    display: flex;
    text-align: left
}

bsk-specs-table th {
    width: 80%
}

bsk-specs-table td {
    width: 20%;
    min-width: fit-content
}

bsk-sticky-summary .sticky {
    top: -150px;
    transition: top .5s;
    z-index: 999;
    opacity: .95
}

bsk-sticky-summary .sticky.fixed {
    top: 0
}

bsk-sticky-summary .d-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: "image titles spec cta"
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    bsk-sticky-summary .d-grid {
        grid-template-columns:1fr
    }
}

@media only screen and (min-width: 767px) and (max-width:1199px) {
    bsk-sticky-summary .d-grid {
        grid-template-columns:1fr 1fr
    }
}

@media only screen and (min-width: 1200px) {
    bsk-sticky-summary .d-grid {
        grid-template-columns:1fr 1fr 1fr;
        column-gap: 2rem
    }
}

bsk-sticky-summary div:has([slot=image]) {
    grid-column: 1
}

@media only screen and (max-width: 767px) {
    bsk-sticky-summary .sticky img {
        object-fit:cover;
        max-height: 60px
    }
}

bsk-sticky-summary :is([slot=shortlist],[slot=titles]) {
    display: flex;
    justify-content: center;
    align-items: center
}

bsk-sticky-summary bsk-shortlist-local-heart {
    position: relative!important;
    margin: 0!important
}

bsk-sticky-summary [slot=shortlist] {
    padding-left: .75rem;
    transform: translateY(1.5px)
}

bsk-vehicle-img-count {
    position: absolute;
    z-index: 2;
    left: 1.5rem;
    bottom: 1rem
}

bsk-version-select .form-control {
    color: var(--bs-gray-600);
    margin-bottom: var(--bsk-spacer-3);
    line-height: 1.5
}

bsk-version-select .form-control:focus {
    color: var(--bs-gray-900)
}

bsk-version-select .version-select {
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto;
    row-gap: .5rem
}

bsk-version-select .form-group {
    grid-column: 1/span all
}

bsk-version-select .form-group:has([type=submit]) {
    grid-column: 1/span all
}

@media only screen and (min-width: 768px) and (max-width:1199px) {
    bsk-version-select .version-select {
        grid-template-columns:repeat(2,1fr);
        column-gap: 1rem
    }

    bsk-version-select .form-group:nth-of-type(odd):not(:has([type=submit])) {
        grid-column: 1/span 1
    }

    bsk-version-select .form-group:nth-of-type(2n) {
        grid-column: 2/span 1
    }
}

@media only screen and (min-width: 1200px) {
    bsk-version-select .version-select {
        grid-template-columns:repeat(4,1fr);
        column-gap: 1rem;
        row-gap: 2rem
    }

    bsk-version-select .form-group {
        grid-column: var(--col-start-desk)/span 1
    }
}

bsk-version :is([slot=spec-items] ul,.spec-items) {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto
}

bsk-version:has(.modal.show) :is([slot=spec-items] ul,.spec-items) {
    column-gap: 1rem;
    row-gap: .5rem
}

@media only screen and (min-width: 768px) and (max-width:1199px) {
    bsk-version :is([slot=spec-items] ul,.spec-items) {
        grid-template-columns:repeat(2,1fr)
    }
}

@media only screen and (min-width: 1200px) {
    bsk-version :is([slot=spec-items] ul,.spec-items) {
        grid-template-columns:repeat(3,1fr)
    }
}

bsk-version :is([slot=spec-items] ul,.spec-items) li {
    grid-column: auto/span 1;
    padding: .5rem
}

bsk-version:has(.modal.show) :is([slot=spec-items] ul,.spec-items) li {
    padding: .25rem
}