.reporting-container {
    padding: 1rem;
    width: 100%;
}

.reporting-header {
    text-align: left;
    margin-bottom: 2rem;
}

.reporting-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.filter-section {
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.filter-item {
    display: flex;
    flex-direction: column;
}

    .filter-item label {
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

.filter-input,
.filter-select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 100%;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.stat-card {
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

    .stat-card h3 {
        font-size: 0.875rem;
        color: #666;
        margin-bottom: 0.5rem;
    }

.stat-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
}

.chart-card {
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

    .chart-card h3 {
        margin-bottom: 1rem;
        font-size: 1rem;
        color: #333;
    }

.error-message {
    background: #fee2e2;
    border: 1px solid #ef4444;
    color: #b91c1c;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.loading-message {
    text-align: center;
    padding: 2rem;
    color: #666;
}