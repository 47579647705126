.accidentsupport-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.accidentsupport-header {
    text-align: center;
    margin-bottom: 2rem;
}

.accidentsupport-header h1 {
    color: #333;
    font-size: 2.5rem;
    font-weight: 600;
}
.accident-container {
    padding: 1rem;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.accident-form-wrapper {
    width: 100%;
    padding: 2rem;
}

.accident-title {
    color: #333;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.accident-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.accident-form-section {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #eee;
}

.accident-form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.accident-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

    .accident-form-group label {
        font-weight: 600;
        color: #444;
    }

    .accident-form-group input[type="text"],
    .accident-form-group input[type="email"],
    .accident-form-group input[type="tel"],
    .accident-form-group input[type="date"],
    .accident-form-group select,
    .accident-form-group textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
        min-height: 42px;
    }

    .accident-form-group select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1em;
        padding-right: 2.5rem;
    }

    .accident-form-group textarea {
        min-height: 100px;
        resize: vertical;
    }

.accident-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.accident-checkbox-input {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.accident-warning-notes {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #fee2e2;
    border-radius: 4px;
}

    .accident-warning-notes p {
        color: #dc2626;
        font-weight: 500;
        margin: 0;
    }

    .accident-warning-notes ul {
        margin-top: 0.5rem;
        margin-bottom: 0;
        padding-left: 1.5rem;
    }

    .accident-warning-notes li {
        color: #dc2626;
        margin-bottom: 0.25rem;
    }

.accident-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.accident-submit-button {
    background: #dc2626;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .accident-submit-button:hover {
        background: #b91c1c;
    }

.accident-cancel-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 1rem;
    color: #374151;
    background: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .accident-cancel-button:hover {
        background: #f3f4f6;
    }

.accident-array-item {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    background: white;
}

.accident-array-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.accident-array-item-title {
    font-weight: 600;
    color: #374151;
}

.accident-remove-button {
    background: #ef4444;
    color: white;
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .accident-remove-button:hover {
        background: #dc2626;
    }

.accident-add-button {
    background: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

    .accident-add-button:hover {
        background: #2563eb;
    }

.accident-loading {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #666;
}

.accident-error {
    color: #dc2626;
    padding: 1rem;
    margin: 1rem 0;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    border-radius: 4px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .accident-form-wrapper {
        padding: 1rem;
    }

    .accident-form-row {
        grid-template-columns: 1fr;
    }

    .accident-title {
        font-size: 1.5rem;
    }

    .accident-form-actions {
        flex-direction: column-reverse;
    }

    .accident-submit-button,
    .accident-cancel-button {
        width: 100%;
    }
}

