.user-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e2e8f0;
    width: 450px;
    margin: 1rem;
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.user-business {
    color: #4b5563;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    font-style: italic;
}

/* Update user info styling to handle long text better */
.user-info p {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    color: #4b5563;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    line-height: 1.4;
}

.user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background: #4285f4;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .user-avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .user-avatar span {
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
    }

.user-name {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
}

.user-status {
    display: inline-block;
    padding: 0.35rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

    .user-status.active {
        background-color: #dcfce7;
        color: #166534;
    }

    .user-status.inactive {
        background-color: #fee2e2;
        color: #991b1b;
    }

    .user-info p {
        margin: 0.5rem 0;
        font-size: 0.875rem;
        color: #4b5563;
    }

.user-email, .user-mobile {
    word-break: break-all;
}

.user-level {
    font-weight: 500;
}

/* Edit Form Styles */
.user-card.editing {
    width: 100%;
    max-width: 500px;
}

.profile-upload {
    text-align: center;
    margin-bottom: 1.5rem;
}

.current-image {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
}

.preview-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #e5e7eb;
}

.remove-image-button {
    position: absolute;
    top: -8px;
    right: -50px;
    background: #ef4444;
    color: white;
    border: none;
    width: 60px;
    height: 24px;
    border-radius: 10%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.file-input {
    margin: 1rem 0;
}

.form-group {
    margin-bottom: 1rem;
}

    .form-group label {
        display: block;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: #374151;
    }

    .form-group input,
    .form-group select {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #d1d5db;
        border-radius: 0.375rem;
        font-size: 0.875rem;
    }

.card-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    
}

    .card-actions button {
        flex: 1;
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        border: none;
        transition: all 0.2s;
    }

.submit-button, .edit-button {
    background-color: #4285f4;
    color: white;
}

    .submit-button:hover, .edit-button:hover {
        background-color: #3367d6;
    }

.cancel-button {
    background-color: #e5e7eb;
    color: #374151;
}

    .cancel-button:hover {
        background-color: #d1d5db;
    }

.deactivate-button {
    background-color: #ef4444;
    color: white;
}

    .deactivate-button:hover {
        background-color: #dc2626;
    }

.error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}
