/* Base container layout */
.home-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.main-content {
    flex: 1;
    padding-top: 80px;
    padding-bottom: 10px;
}

.form-links {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.link-button {
    background: none;
    border: none;
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
}

    .link-button:hover {
        color: #003d82;
    }

.page-title-section{
    text-align:center;
    padding-top:20px;
    padding-bottom:10px;
}

/* Alert styles */
.alert {
    margin: 1rem auto;
    max-width: 1200px;
    padding: 1rem;
    border-radius: 0;
    text-align: center;
}

.alert-info {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

/* Dashboard section */
.go-to-dashboard {
    text-align: center;
    padding: 2rem 0;
}

.dashboard-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #000;
    color: white;
    text-decoration: none;
    border-radius: 0;
    font-weight: 600;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .dashboard-button:hover {
        background-color: #333;
        color: white;
        text-decoration: none;
    }

/* Forms layout */
.forms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    gap: 40px;
}

.form-section {
    flex: 1;
    background: white;
    padding: 20px;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .form-section h2 {
        color: #333;
        margin-bottom: 25px;
        font-size: 24px;
        text-align: center;
    }

/* Form elements */
.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group.half {
    flex: 1;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 0;
    font-size: 14px;
    background-color: white;
}

    .form-group input:focus,
    .form-group select:focus,
    .form-group textarea:focus {
        outline: none;
        border-color: #000;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

.form-group select {
    cursor: pointer;
    appearance: none;
    padding-right: 30px;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
}

/* Buttons */
.submit-button, .explore-now {
    padding: 12px 24px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .submit-button:hover,
    .explore-now:hover {
        background-color: #333;
        transform: translateY(-1px);
    }

    .submit-button:disabled,
    .explore-now:disabled {
        background-color: #666;
        cursor: not-allowed;
        transform: none;
    }

.link-button {
    background: none;
    border: none;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
}

    .link-button:hover {
        color: #333;
    }

/* Messages */
.error-message {
    color: #dc3545;
    margin-bottom: 15px;
    font-size: 14px;
    padding: 10px;
    background-color: #ffe6e6;
    border: 1px solid #dc3545;
    border-radius: 0;
}

.success-message {
    color: #155724;
    margin-bottom: 15px;
    font-size: 14px;
    padding: 10px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 0;
}

/* Form links */
.form-links {
    margin-top: 20px;
    text-align: center;
}

/* Footer */
footer {
    margin-top: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
    .main-content {
        padding-top: 60px;
    }

    .forms-container {
        flex-direction: column;
        padding: 15px;
    }

    .form-row {
        flex-direction: column;
        gap: 0;
    }

    .form-group.half {
        margin-bottom: 20px;
    }

    .alert {
        margin: 1rem 15px;
    }
}
