.accidentreports-container {
    padding: 1rem;
    width: 100%;
}

.accidentreports-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.accidentreports-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.accidentreports-table-container {
    background: white;
    width: 100%;
}

.accidentreports-table {
    width: 100%;
    border-collapse: collapse;
}

.accidentreports-table th {
    background-color: #f9fafb;
    padding: 0.75rem 1rem;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #6b7280;
    border-bottom: 1px solid #e5e7eb;
}

.accidentreports-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    font-size: 0.875rem;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: background-color 0.2s;
}

.action-button.add-new {
    background-color: #000;
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
}

.action-button.edit-button {
    background-color: #3b82f6;
    color: white;
    min-width: 60px;
}

/* Status badge styles */
.status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
}

/* Status badge styles */
.status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
}

.status-badge-draft {
    background-color: #e5e7eb;
    color: #374151;
}

.status-badge-submitted {
    background-color: #dbeafe;
    color: #1e40af;
}

.status-badge-inprogress {
    background-color: #fef3c7;
    color: #92400e;
}

.status-badge-pending {
    background-color: #ffedd5;
    color: #9a3412;
}

.status-badge-resolved {
    background-color: #dcfce7;
    color: #166534;
}

.status-badge-closed {
    background-color: #f3e8ff;
    color: #6b21a8;
}
/* Mobile Styles */
@media screen and (max-width: 768px) {
    .accidentreports-container {
        padding: 0;
    }

    .accidentreports-header {
        padding: 1rem;
    }

        .accidentreports-header h2 {
            font-size: 1rem;
        }

    .action-button.add-new {
        font-size: 0.75rem;
        padding: 0.375rem 0.75rem;
    }

    .accidentreports-table th,
    .accidentreports-table td {
        font-size: 0.75rem;
        padding: 0.5rem;
    }

    /* Hide less important columns on mobile */
    .accidentreports-table th:nth-child(3),
    .accidentreports-table td:nth-child(3),
    .accidentreports-table th:nth-child(6),
    .accidentreports-table td:nth-child(6) {
        display: none;
    }

    .action-buttons {
        gap: 0.25rem;
    }

    .action-button {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        min-width: 50px;
    }
}