/* Sidebar Container */
.sidebar-container {
    width: 300px;
    min-height: calc(100vh - 72px); /* Adjust based on header height */
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

/* Main Sidebar Content */
.sidebar {
    position: sticky;
    top: 72px; /* Match header height */
    height: calc(100vh - 72px);
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

/* Sidebar Links Container */
.sidebar-links {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

    .sidebar-links li {
        width: 100%;
    }

/* Navigation Button Styles */
.sidebar-button {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 24px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    position: relative;
}

    .sidebar-button:hover {
        background-color: #333;
        transform: translateY(-1px);
        color: white;
        text-decoration: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .sidebar-button.active {
        background-color: #333;
        color: white;
    }

    .sidebar-button:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

/* Logout Button Style */
.logout-button {
    margin-top: auto;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 0;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .logout-button:hover {
        background-color: #c82333;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2);
    }

    .logout-button:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.2);
    }

/* Overlay for Mobile */
.sidebar-overlay {
    position: fixed;
    top: 72px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 72px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

    .sidebar-overlay.active {
        opacity: 1;
        pointer-events: all;
    }

/* Scrollbar Styling */
.sidebar::-webkit-scrollbar {
    width: 6px;
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

    .sidebar::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar-container {
        position: fixed;
        top: 72px;
        left: -300px;
        height: calc(100vh - 72px);
        transition: left 0.3s ease;
    }

        .sidebar-container.active {
            left: 0;
        }

    .sidebar {
        height: 100%;
        padding: 1.5rem;
    }

    .sidebar-button,
    .logout-button {
        padding: 10px 20px;
        font-size: 13px;
    }
}

/* Small Screen Adjustments */
@media (max-height: 600px) {
    .sidebar {
        padding: 1rem;
    }

    .sidebar-links {
        gap: 5px;
    }

    .sidebar-button,
    .logout-button {
        padding: 8px 16px;
        font-size: 12px;
    }
}
.admin-separator {
    width: 100%;
    padding: 0 1rem;
}
