.faqs-container {
    padding: 1rem;
    max-width: 100%;
}

.faqs-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}


.faqs-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.faqs-container-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}


.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: background-color 0.2s;
}

.action-button.add-new {
    background-color: #000;
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
}

.faq-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
}

.faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

    .faq-header h3 {
        margin: 0;
        font-size: 18px;
    }

.faq-actions {
    display: flex;
    gap: 10px;
}

.faq-answer {
    margin: 0;
    line-height: 1.5;
    color: #666;
}

.form-wrapper {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
}

.faq-form .form-section {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
    }

    .form-group input,
    .form-group textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
    }

    .form-group textarea {
        resize: vertical;
    }

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.link-button {
    background: none;
    border: none;
    color: #0066cc;
    cursor: pointer;
    padding: 8px 16px;
}

    .link-button:hover {
        text-decoration: underline;
    }

.faq-button {
    background: #0066cc;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

    .faq-button:hover {
        background: #0052a3;
    }

.success-message {
    background-color: #dff0d8;
    color: #3c763d;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.error-message {
    background-color: #f2dede;
    color: #a94442;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
}