.userprofile-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.userprofile-header {
    text-align: center;
    margin-bottom: 2rem;
}

.userprofile-header h1 {
    color: #333;
    font-size: 2.5rem;
    font-weight: 600;
}

.remove-profile-image-button {    
    background: #ef4444;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-left:10px;
}