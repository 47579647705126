.settings-container {
    padding: 1rem;
    width: 100%;
}

.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.settings-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.settings-page-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.settings-page-table {
    width: 100%;
    border-collapse: collapse;
}

.settings-page-table th,
.settings-page-table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.settings-page-table th {
    background: #f5f5f5;
    font-weight: 600;
}

.settings-page-actions {
    display: flex;
    gap: 8px;
}

.settings-page-edit-button,
.settings-page-hide-button,
.settings-page-show-button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.settings-page-edit-button {
    background: #4a90e2;
    color: white;
}

.settings-page-hide-button {
    background: #e74c3c;
    color: white;
}

.settings-page-show-button {
    background: #2ecc71;
    color: white;
}

.settings-page-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
}

.settings-page-status.active {
    background: #e8f5e9;
    color: #2ecc71;
}

.settings-page-status.inactive {
    background: #ffebee;
    color: #e74c3c;
}

.settings-page-inactive-row {
    background: #f9f9f9;
}

.settings-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.settings-page-modal {
    background: white;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
}

.settings-page-modal h2 {
    margin-bottom: 20px;
}

.settings-page-form-group {
    margin-bottom: 16px;
}

.settings-page-form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.settings-page-form-group input,
.settings-page-form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.settings-page-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.settings-page-submit-button,
.settings-page-cancel-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.settings-page-submit-button {
    background: #4a90e2;
    color: white;
}

.settings-page-cancel-button {
    background: #ddd;
}

.settings-page-error {
    background: #fee;
    color: #e74c3c;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.settings-page-success {
    background: #e8f5e9;
    color: #2ecc71;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.settings-page-loading {
    padding: 20px;
    text-align: center;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: background-color 0.2s;
}

.action-button.add-new {
    background-color: #000;
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
}

/* CKEditor container styles */
.ck-editor__editable {
    min-height: 300px !important;
}

/* Content modal specific styles */
.content-modal {
    width: 90%;
    max-width: 800px;
}

    .content-modal .settings-page-form-group {
        margin-bottom: 24px;
    }

.preview-button {
    margin-left: 8px;
    padding: 2px 8px;
    font-size: 12px;
    background: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.settings-page-preview-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #2ecc71;
    color: white;
}

/* Content preview styles */
.content-preview {
    display: flex;
    align-items: center;
    gap: 8px;
}