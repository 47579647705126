.manageusers-container {
    padding: 1rem;
    max-width: 100%;
}

.manageusers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.manageusers-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.manageusers-filter-select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 350px;
    margin: 0.5rem;
}

.users-wrapper {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.users-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.users-actions {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
}

.users-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: background-color 0.2s;
}

    .action-button.add-new {
        background-color: #000;
        color: white;
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
    }

.new-user-form {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: #f8fafc;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
}

    .new-user-form h4 {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

.success-message {
    padding: 1rem;
    background-color: #dcfce7;
    border: 1px solid #86efac;
    color: #166534;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.error-message {
    padding: 1rem;
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    color: #991b1b;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.profile-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.2rem;
    color: #666;
}