/* Dashboard Layout */
.dashboard-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f6f8fa;
}

/* Dashboard Header Styles */
.dashboard-header {
    background: #fff;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
}

.dashboard-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
}

    .menu-toggle span {
        display: block;
        width: 24px;
        height: 2px;
        background: #353231;
        margin: 4px 0;
        transition: 0.3s;
    }

.header-right {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.user-name {
    font-weight: 500;
    color: #353231;
}

/* Main content area with sidebar */
.dashboard-main {
    display: flex;
    flex: 1;
}

/* Dashboard Content Area */
.dashboard-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 72px);
    padding: 2rem;
    background-color: #f6f8fa;
}

.content-wrapper {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
}

/* Dashboard Footer */
.dashboard-footer {
    background: #fff;
    padding: 1rem 2rem;
    border-top: 1px solid #eaecef;
    margin-top: auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links {
    display: flex;
    gap: 1rem;
}

    .footer-links a {
        color: #353231;
        text-decoration: none;
        font-size: 0.9rem;
    }

        .footer-links a:hover {
            text-decoration: underline;
        }

/* Loading State */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #353231;
}

/* Dashboard Cards and Widgets */
.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.dashboard-card {
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #353231;
}

.card-content {
    color: #555;
}

/* Dashboard Tables */
.dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

    .dashboard-table th,
    .dashboard-table td {
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid #eaecef;
    }

    .dashboard-table th {
        font-weight: 600;
        background-color: #f8f9fa;
    }

    .dashboard-table tr:hover {
        background-color: #f8f9fa;
    }

/* Dashboard Buttons */
.dashboard-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .dashboard-button:hover {
        background-color: #333;
    }

.secondary-button {
    background-color: #f0f0f0;
    color: #353231;
}

    .secondary-button:hover {
        background-color: #e0e0e0;
    }

/* Utility Classes */
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

/* Responsive Styles */
@media (max-width: 1700px) {
    .menu-toggle {
        display: block;
    }
}

@media (max-width: 768px) {
    .dashboard-header-content,
    .footer-content {
        flex-direction: column;
        gap: 1rem;
    }

    .dashboard-page-content {
        padding: 1rem;
    }

    .content-wrapper {
        padding: 1rem;
    }

    .header-right {
        width: 100%;
        justify-content: center;
    }

    .dashboard-cards {
        grid-template-columns: 1fr;
    }

    .dashboard-table {
        display: block;
        overflow-x: auto;
    }

    .dashboard-main {
        position: relative;
    }
}

/* Small screens */
@media (max-width: 480px) {
    .dashboard-header {
        padding: 1rem;
    }

    .header-right {
        flex-direction: column;
        gap: 1rem;
    }

    .user-info {
        flex-direction: column;
        text-align: center;
    }

    .dashboard-footer {
        padding: 1rem;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }
}
