.businessprofiles-container {
    padding: 1rem;
    width: 100%;
}

.businessprofiles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.businessprofiles-header h1 {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
}

.businessprofiles-table-container {
    background: white;
    width: 100%;
}

.businessprofiles-table {
    width: 100%;
    border-collapse: collapse;
}

.businessprofiles-table th {
    background-color: #f9fafb;
    padding: 0.75rem 1rem;
    text-align: left;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #6b7280;
    border-bottom: 1px solid #e5e7eb;
}

.businessprofiles-table td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    font-size: 0.875rem;
}

.website-link {
    color: #2563eb;
    text-decoration: none;
}

.website-link:hover {
    text-decoration: underline;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    transition: background-color 0.2s;
}

.action-button.add-new {
    background-color: #000;
    color: white;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
}

.action-button.hold-button {
    background-color: #000;
    color: white;
    min-width: 60px;
}

.action-button.edit-button {
    background-color: #3b82f6;
    color: white;
    min-width: 60px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .businessprofiles-container {
        padding: 0;
    }

    .businessprofiles-header {
        padding: 1rem;
    }

        .businessprofiles-header h2 {
            font-size: 1rem;
        }

    .action-button.add-new {
        font-size: 0.75rem;
        padding: 0.375rem 0.75rem;
    }

    .businessprofiles-table th,
    .businessprofiles-table td {
        font-size: 0.75rem;
        padding: 0.5rem;
    }

        /* Hide less important columns on mobile */
        .businessprofiles-table th:nth-child(2),
        .businessprofiles-table td:nth-child(2),
        .businessprofiles-table th:nth-child(5),
        .businessprofiles-table td:nth-child(5) {
            display: none;
        }

    .action-buttons {
        gap: 0.25rem;
    }

    .action-button {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        min-width: 50px;
    }
}
