.businessprofile-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.businessprofile-header {
    text-align: center;
    margin-bottom: 2rem;
}

.businessprofile-header h1 {
    color: #333;
    font-size: 2.5rem;
    font-weight: 600;
}

.addresses-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.address-card {
    display: flex;
    flex-direction: column; /* Ensure vertical layout */
    justify-content: space-between; /* Push content to top and buttons to bottom */
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    min-height: 200px; /* Set a minimum height for consistent layout */
}

    .address-card.editing {
        background-color: #f8fafc;
    }

    .address-card p {
        margin-bottom: 0.5rem;
    }

.card-actions {
    margin-top: auto; /* Push buttons to the bottom */
    display: flex;
    gap: 0.5rem;
    padding-top: 1rem;
}

.edit-button, .delete-button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
}

.edit-button {
    background-color: #3b82f6;
    color: white;
    border: none;
}

.delete-button {
    background-color: #ef4444;
    color: white;
    border: none;
}

.edit-button:hover {
    background-color: #2563eb;
}

.delete-button:hover {
    background-color: #dc2626;
}

.edit-button:disabled, .delete-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.loading {
    opacity: 0.5;
}

.error-message {
    color: #ef4444;
    margin-top: 0.5rem;
    font-size: 0.875rem;
}

.new-address-form {
    max-width: 500px;
    margin-bottom: 2rem;
}

.new-address-form .address-card {
    max-width: 100%;
}

.new-address-form .form-group {
    margin-bottom: 1rem;
}

.new-address-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.new-address-form .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
}
