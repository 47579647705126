.qr-container {
    padding: 1rem;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.qr-form-wrapper {
    width: 100%;
    padding: 2rem;
}

.qr-title {
    color: #333;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.qr-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.qr-date-input {
    min-width: 120px; /* Smaller to fit alongside time selectors */
}

.time-select {
    width: 50px; /* Wider for easier selection */
}

@media (max-width: 600px) {
    .date-time-group {
        display: flex;
        flex-direction: column; /* Stacks the time fields below the date fields */
        align-items: flex-start;
    }

    .qr-date-input,
    .time-select {
        width: 100%; /* Ensures full-width inputs on mobile */
    }
}

.date-time-group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.qr-form-section {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #eee;
}

.qr-form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.qr-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

    .qr-form-group label {
        font-weight: 600;
        color: #444;
    }

    .qr-form-group input[type="text"],
    .qr-form-group input[type="email"],
    .qr-form-group input[type="tel"],
    .qr-form-group input[type="date"],
    .qr-form-group select,
    .qr-form-group textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
        min-height: 42px;
    }

    .qr-form-group select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1em;
        padding-right: 2.5rem;
    }

    .qr-form-group textarea {
        min-height: 100px;
        resize: vertical;
    }

.qr-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.qr-checkbox-input {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.qr-form-notes {
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 4px;
}

.qr-note {
    color: darkred;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.4;
}

.qr-form-actions {
    display: flex;
    justify-content: center;
}

.qr-submit-button {
    background: #007bff;
    color: white;
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .qr-submit-button:hover {
        background: #0056b3;
    }

.qr-submit-button2 {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .qr-submit-button2:hover {
        background: #0056b3;
    }

.qr-loading {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #666;
}

.qr-error {
    color: #dc3545;
    padding: 1rem;
    margin: 1rem 0;
    background: #fde8e8;
    border-radius: 4px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .qr-form-wrapper {
        padding: 1rem;
    }

    .qr-form-row {
        grid-template-columns: 1fr;
    }

    .qr-title {
        font-size: 1.5rem;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .qr-container {
        background: #1a1a1a;
    }

    .qr-form-section {
        background: #242424;
        border-color: #333;
    }

    .qr-form-group label {
        color: #e0e0e0;
    }

    .qr-form-group input,
    .qr-form-group select,
    .qr-form-group textarea {
        background: #333;
        border-color: #444;
        color: #fff;
    }

    .qr-form-notes {
        background: #242424;
    }
}
.time-selector {
    display: flex;
    align-items: center;
    gap: 4px;
}

.time-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 70px;
    appearance: none;
    background-color: white;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 12px;
    cursor: pointer;
}

.time-separator {
    font-weight: bold;
    color: #666;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .time-select {
        background-color: #333;
        border-color: #444;
        color: #fff;
    }

    .time-separator {
        color: #ccc;
    }
}

.qr-date-input {
    flex: 1;
    min-width: 150px;
}
.qr-error-container {
    margin: 1rem 0;
    padding: 0.75rem;
    border-radius: 0.375rem;
    background-color: #FEE2E2;
    border: 1px solid #FCA5A5;
}

.qr-error-message {
    display: flex;
    align-items: center;
    color: #DC2626;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.qr-error-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

/* Update existing loading style if needed */
.qr-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.125rem;
    color: #4B5563;
}

/* Make the entire date input field clickable */
input[type="date"] {
    position: relative;
    cursor: pointer;
    min-width: 150px; /* Ensure enough space for the date */
}

    /* Hide the default calendar icon in Chrome */
    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

/* Optional: Add your own calendar icon if desired */
input[type="date"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 20px;
    padding-right: 32px; /* Make space for the icon */
}

    /* Remove default arrow in Firefox */
    input[type="date"]::-moz-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    /* Ensure consistent styling across browsers */
    input[type="date"].p-2.border.rounded {
        height: 40px; /* Match your other input heights */
        padding: 0.5rem;
        border-radius: 0.375rem;
        border: 1px solid #e2e8f0;
        background-color: #fff;
    }

    /* Optional: Add hover effect */
    input[type="date"]:hover {
        background-color: #f8fafc;
    }

    /* Style HTML content from CKEditor */
    .qr-note ul, .qr-note ol {
        margin-left: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .qr-note p {
        margin-bottom: 0.5rem;
    }

    .qr-note :last-child {
        margin-bottom: 0;
    }